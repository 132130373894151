<template>
  <div class="box">
    <!-- <headers></headers> -->
    <div>
      <!-- 订单-查询 -->
      <div class="query">
        <div class="query-category">
          <p class="query-category-title">订单</p>
          <div style="flex:1;"></div>
          <p @click="payClick(9)" class="query-category-btn">选择会员</p>
          <p @click="payClick(10)" class="query-category-btn">选择导购</p>
          <p class="query-category-clear" @click="deletetChrt()">清空购物车</p>
        </div>
        <div class="query-search">
          <div class="scanning">
            <img src="../assets/images/scanningIcon.png" class="scanning-icon" alt="">
            <input v-model="scanningNum" @keyup.enter="search(1)" placeholder="商家编码/条码/单位条码" type="text">
          </div>
          <p @click="search(1)" class="search">搜索</p>
        </div>
      </div>
      <!-- 会员信息 -->
      <div class="member">
        <p v-if="independentType === 1" class="member-cashier">收银员：{{independent}} <span
            v-if="independent2 !== '请选择'">{{independent2}}</span> <img src="../assets/images/closeIcon.png"
            @click="deleteGuide()" alt=""> </p>
        <div class="member-detail" v-if="memberContent != '' && memberContent != null">
          <img class="close" @click="closeMember()" src="../assets/images/closeIcon.png" alt="">
          <div class="user">
            <img :src="memberContent.headPortrait" class="user-photo" alt="">
            <div class="user-detail">
              <div class="user-detail-information">
                <p class="name">{{memberContent.memberName}}</p>
                <p class="phone">{{memberContent.phone}}</p>
              </div>
              <div class="user-detail-information" style="margin-top:4rem;">
                <p class="integral">{{memberContent.memberPoints}} points</p>
                <img v-if="memberContent.level == 1" src="../assets/images/grade01.png" class="grade" alt="">
                <img v-if="memberContent.level == 2" src="../assets/images/grade02.png" class="grade" alt="">
                <img v-if="memberContent.level == 3" src="../assets/images/grade03.png" class="grade" alt="">
                <img v-if="memberContent.level == 4" src="../assets/images/grade04.png" class="grade" alt="">
                <img v-if="memberContent.level == 5" src="../assets/images/grade05.png" class="grade" alt="">
                <img src="" class="icon" alt="">
              </div>
            </div>
            <div class="user-line"></div>
            <div class="user-list">
              <p class="user-list-name" v-if="memberContent.level == 1">粉丝</p>
              <p class="user-list-name" v-if="memberContent.level == 2">会员</p>
              <p class="user-list-name" v-if="memberContent.level == 3">VIP会员</p>
              <p class="user-list-name" v-if="memberContent.level == 4">亲友会员</p>
              <p class="user-list-name" v-if="memberContent.level == 5">媒体会员</p>
              <p class="user-list-txt">会员类型</p>
            </div>
            <div class="user-list">
              <p class="user-list-name">{{memberContent.createTime}}</p>
              <p class="user-list-txt">入会时间</p>
            </div>
            <div class="user-list">
              <p class="user-list-name">{{memberContent.daysNum == null?'暂无消费记录':memberContent.daysNum + '天'}}</p>
              <p class="user-list-txt">距上次消费天数</p>
            </div>
            <div class="user-list">
              <p class="user-list-name">{{memberContent.onlineConsumeNum}}次</p>
              <p class="user-list-txt">线上下单次数</p>
            </div>
            <div class="user-list">
              <p class="user-list-name">{{memberContent.offlineConsumeNum}}次</p>
              <p class="user-list-txt">线下下单次数</p>
            </div>
            <div class="user-list">
              <p class="user-list-name">{{memberContent.consumeMoney}}元</p>
              <p class="user-list-txt">累计消费金额</p>
            </div>
          </div>
          <div class="record">
            <p style="font-size:12rem;color:#999999;">上次购买记录：</p>
            <div class="record-txt" :style="recordHeight">
              <p v-for="(item,i) in memberContent.memberPurchaseRecordVOList" v-bind:key="i"
                style="padding-bottom:10rem;">{{item.localGoodsName}} {{item.specName}} ¥{{item.sellingPrice}}
                {{item.specNo}}
              </p>
              <!-- <p>四四四四T桖  黑色-S  ¥299 VLONMT0YRJF</p> -->
            </div>
            <img v-if="recordStatu == 0" @click="recordClick()" src="../assets/images/down.png"
              style="width:12rem;height:6rem;" alt="">
            <img v-if="recordStatu == 1" @click="recordClick()" src="../assets/images/down.png"
              style="width:12rem;height:6rem;transform: rotate(180deg);" alt="">
          </div>
        </div>
      </div>
      <!-- 商品信息 -->
      <div class="order">
        <div v-for="(item,index) in orderList" :key="index" class="order-list">
          <img :src="item.imgUrl" class="photo" alt="">
          <div class="detail">
            <div class="detail-list">
              <p class="name">{{item.goodsName}}</p>
              <img class="delete" @click="deleteOrderList(index)" src="../assets/images/deleteIcon.png" alt="">
            </div>
            <div class="detail-list" style="margin-top:6rem;">
              <p class="title">{{item.specName}}</p>
              <p class="code">商家编码:{{item.specNo}}</p>
            </div>
            <div class="detail-list" style="margin-top:4rem;">
              <p class="title">库存:{{item.stock === null?0:item.stock }}</p>
            </div>
            <div class="detail-list" style="margin-top:6rem;">
              <p class="txt">售价:￥{{item.localPrice}}</p>
              <p class="txt" style="margin-left:15rem;" v-if="item.localDiscountPrice != null">折后价: </p>
              <p class="txt" style="color:#BE0000;flex:1;" v-if="item.localDiscountPrice != null">
                ￥{{item.localDiscountPrice}}</p>
              <p style="flex:1;" v-if="item.localDiscountPrice == null"></p>
              <img class="record-icon" @click="editNum(1,index)" src="../assets/images/record01.png" alt="">
              <input type="number" @blur="editNumBlur(item.number,index)" v-model="item.number">
              <img class="record-icon" @click="editNum(2,index)" src="../assets/images/record02.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="footer">
        <div class="footer-detail">
          <!-- <p class="footer-detail-name">单品优惠：-￥</p>
          <p class="footer-detail-num">480</p>
          <p class="footer-detail-line"></p> -->
          <p class="footer-detail-name" v-if="discountStatu == 1">整单折扣：</p>
          <div class="footer-detail-num" style="position:relative;padding-right:20rem;" v-if="discountStatu == 1">
            {{discountTxt == '折扣比例' ?discountRatio:-(totalAmountPrice-discountPrice-integralInput)}}
            <img @click="closeDiscountStatu()" style="width:14rem;height:14rem;position:absolute;right:-4rem;top:-6rem;"
              src="../assets/images/closeIcon.png" alt="">
          </div>
          <p class="footer-detail-name" v-if="discountStatu != 1">会员折扣：-￥</p>
          <p class="footer-detail-num" v-if="discountStatu != 1">{{memberPrice}}</p>
          <p class="footer-detail-line"></p>
          <p class="footer-detail-name">积分抵扣：-￥</p>
          <p class="footer-detail-num">{{integralInput == ''?'0':integralInput}}</p>
          <div style="flex:1;"></div>
          <p class="footer-detail-txt">商品总数:</p>
          <p class="footer-detail-sum">{{goodsTotalNum}}</p>
          <p class="footer-detail-txt" style="text-decoration:line-through">原价:￥{{localPrice}}</p>
          <p class="footer-detail-sum"></p>
          <p class="footer-detail-txt">合计金额:</p>
          <p class="footer-detail-symbol">￥</p>
          <p class="footer-detail-price">{{totalAmountPrice2 - integralInput}}</p>
        </div>
        <div class="footer-nav">
          <p @click="footerClick(index)" class="footer-nav-list" v-for="(item,index) in footerList" :key="index"
            :class="{footerFirst:index == 0,activite:index==footerIndex}">{{item}}</p>
          <div style="flex:1;"></div>
          <p class="take-order" @click="hangingOrder()">{{hangingType == 0?'取单':'挂单'}}</p>
          <p class="footer-nav-line"></p>
          <p @click="payClick(1)" class="footer-nav-pay footer-nav-pos">pos支付</p>
          <p @click="payClick(2)" class="footer-nav-pay">现金支付</p>
          <p @click="payClick(3)" class="footer-nav-pay">押金支付</p>
          <p @click="payClick(4)" class="footer-nav-pay footer-nav-last">组合支付</p>
        </div>
      </div>
    </div>
    <!-- pos支付 -->
    <div v-if="maskType != 0">
      <div @click="payClick(0)" class="layer"></div>
      <!-- pos支付 -->
      <div v-if="maskType == 1" class="masking">
        <div class="masking-header">
          <p>pos支付</p>
          <img @click="payClick(0)" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <p class="masking-title">待付金额：{{totalAmountPrice2 - integralInput}}元</p>
        <p class="masking-wait">等待客户支付</p>
        <div class="masking-pos">
          <p class="title">应收</p>
          <p class="price"><span>￥</span>{{totalAmountPrice2 - integralInput}}</p>
        </div>
        <p class="masking-pay" @click="clickPay(2)">确定并支付</p>
      </div>
      <!-- 现金支付 -->
      <div v-if="maskType == 2" class="masking">
        <div class="masking-header">
          <p>现金支付</p>
          <img @click="payClick(0)" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <p class="masking-title">待付金额：{{totalAmountPrice2 - integralInput}}元</p>
        <p class="masking-wait">等待客户支付</p>
        <div class="masking-cash">
          <div class="masking-cash-detail">
            <div class="list">
              <p class="title">应收金额</p>
              <p class="price">{{totalAmountPrice2 - integralInput}}</p>
              <p class="company">元</p>
            </div>
            <div class="list" style="background:#fff;border:1rem solid #979797;">
              <p class="title">实付现金</p>
              <input class="price" style="width:100rem;margin-left:-2rem;" v-on:input="thisInput(1)" v-model="cashPay">
              <p class="company">元</p>
            </div>
            <div class="list">
              <p class="title">应 找 零</p>
              <p class="price" style="margin-left:8rem;">{{cashGivechange}}</p>
              <p class="company">元</p>
            </div>
          </div>
          <div class="masking-cash-keyboard">
            <div class="list">
              <p class="list-detail" @click="keyBoard(1,1)">1</p>
              <p class="list-detail" @click="keyBoard(1,2)">2</p>
              <p class="list-detail" @click="keyBoard(1,3)">3</p>
            </div>
            <div class="list" style="margin-top:12rem;">
              <p class="list-detail" @click="keyBoard(1,4)">4</p>
              <p class="list-detail" @click="keyBoard(1,5)">5</p>
              <p class="list-detail" @click="keyBoard(1,6)">6</p>
            </div>
            <div class="list" style="margin-top:12rem;">
              <p class="list-detail" @click="keyBoard(1,7)">7</p>
              <p class="list-detail" @click="keyBoard(1,8)">8</p>
              <p class="list-detail" @click="keyBoard(1,9)">9</p>
            </div>
            <div class="list" style="margin-top:12rem;">
              <p class="list-detail" @click="keyBoard(1,'.')" style="line-height:33rem;">.</p>
              <p class="list-detail" @click="keyBoard(1,0)">0</p>
              <div class="list-detail" @click="deleteInput(1)"> <img src="../assets/images/deleteIcon2.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <p class="masking-pay" @click="clickPay(3)">确定并支付</p>
      </div>
      <!-- 押金支付 -->
      <div v-if="maskType == 3" class="masking">
        <div class="masking-header">
          <p>押金支付</p>
          <img @click="payClick(0)" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <p class="masking-title">应付押金：{{totalAmountPrice2 - integralInput}}元</p>
        <p class="masking-wait">应收：{{totalAmountPrice2 - integralInput}}元</p>
        <div class="masking-cash">
          <div class="masking-cash-detail">
            <div class="list">
              <p class="title">应收金额</p>
              <p class="price">{{totalAmountPrice2 - integralInput}}</p>
              <p class="company">元</p>
            </div>
            <div class="list" style="background:#fff;border:1rem solid #979797;">
              <p class="title">实付押金</p>
              <input class="price" style="width:100rem;margin-left:-2rem;" v-model="deposit">
              <p class="company">元</p>
            </div>
          </div>
          <div class="masking-cash-keyboard">
            <div class="list">
              <p class="list-detail" @click="keyBoard(2,1)">1</p>
              <p class="list-detail" @click="keyBoard(2,2)">2</p>
              <p class="list-detail" @click="keyBoard(2,3)">3</p>
            </div>
            <div class="list" style="margin-top:12rem;">
              <p class="list-detail" @click="keyBoard(2,4)">4</p>
              <p class="list-detail" @click="keyBoard(2,5)">5</p>
              <p class="list-detail" @click="keyBoard(2,6)">6</p>
            </div>
            <div class="list" style="margin-top:12rem;">
              <p class="list-detail" @click="keyBoard(2,7)">7</p>
              <p class="list-detail" @click="keyBoard(2,8)">8</p>
              <p class="list-detail" @click="keyBoard(2,9)">9</p>
            </div>
            <div class="list" style="margin-top:12rem;">
              <p class="list-detail" @click="keyBoard(2,'.')" style="line-height:33rem;">.</p>
              <p class="list-detail" @click="keyBoard(2,0)">0</p>
              <div class="list-detail" @click="deleteInput(2)"> <img src="../assets/images/deleteIcon2.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <p class="masking-pay" @click="clickPay(5)">确定并支付</p>
      </div>
      <!-- 组合支付 -->
      <div v-if="maskType == 4" class="masking">
        <div class="masking-header">
          <p>组合支付</p>
          <img @click="payClick(0)" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <p class="masking-title">待付金额：{{totalAmountPrice2 - integralInput}}元</p>
        <p class="masking-wait">应收：{{totalAmountPrice2 - integralInput}}元</p>
        <div class="masking-cash">
          <div class="masking-cash-detail">
            <div class="list" style="background:#fff;border:1rem solid #979797;">
              <div style="margin-left:17rem;width:100rem;">
                <el-dropdown trigger="click" @command="handleCommandSort">
                  <span class="el-dropdown-link">
                    {{combination1}}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu style="margin-top:10rem;" slot="dropdown">
                    <el-dropdown-item class="dropdown-list" command="POS支付">POS支付</el-dropdown-item>
                    <el-dropdown-item class="dropdown-list" command="现金支付">现金支付</el-dropdown-item>
                    <!-- <el-dropdown-item class="dropdown-list" command="微信支付">微信支付</el-dropdown-item> -->
                    <!-- <el-dropdown-item class="dropdown-list" command="支付宝支付">支付宝支付</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <input class="price" style="width:100rem;margin-left:-2rem;" v-on:input="combinationInput(1)"
                @focus="inputFocus(3)" v-model="combinationPay1">
              <p class="company">元</p>
            </div>
            <div class="list" style="background:#fff;border:1rem solid #979797;">
              <div style="margin-left:17rem;width:100rem;">
                <el-dropdown trigger="click" @command="handleCommandSort2">
                  <span class="el-dropdown-link">
                    {{combination2}}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu style="margin-top:10rem;" slot="dropdown">
                    <el-dropdown-item class="dropdown-list" command="POS支付">POS支付</el-dropdown-item>
                    <el-dropdown-item class="dropdown-list" command="现金支付">现金支付</el-dropdown-item>
                    <!-- <el-dropdown-item class="dropdown-list" command="微信支付">微信支付</el-dropdown-item> -->
                    <!-- <el-dropdown-item class="dropdown-list" command="支付宝支付">支付宝支付</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <input class="price" style="width:100rem;margin-left:-2rem;" v-on:input="combinationInput(2)"
                @focus="inputFocus(4)" v-model="combinationPay2">
              <p class="company">元</p>
            </div>
          </div>
          <div class="masking-cash-keyboard">
            <div class="list">
              <p class="list-detail" @click="keyBoard(3,1)">1</p>
              <p class="list-detail" @click="keyBoard(3,2)">2</p>
              <p class="list-detail" @click="keyBoard(3,3)">3</p>
            </div>
            <div class="list" style="margin-top:12rem;">
              <p class="list-detail" @click="keyBoard(3,4)">4</p>
              <p class="list-detail" @click="keyBoard(3,5)">5</p>
              <p class="list-detail" @click="keyBoard(3,6)">6</p>
            </div>
            <div class="list" style="margin-top:12rem;">
              <p class="list-detail" @click="keyBoard(3,7)">7</p>
              <p class="list-detail" @click="keyBoard(3,8)">8</p>
              <p class="list-detail" @click="keyBoard(3,9)">9</p>
            </div>
            <div class="list" style="margin-top:12rem;">
              <p class="list-detail" @click="keyBoard(3,'.')" style="line-height:33rem;">.</p>
              <p class="list-detail" @click="keyBoard(3,0)">0</p>
              <div class="list-detail" @click="deleteInput(3)"> <img src="../assets/images/deleteIcon2.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <p class="masking-pay" @click="combinationPay()">确定并支付</p>
      </div>
      <!-- 整单折扣 -->
      <div v-if="maskType == 5" class="discount">
        <div class="discount-header">
          <p>整单折扣</p>
          <img @click="payClick(0)" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <div class="discount-top">
          <div>
            <el-dropdown trigger="click" @command="handleCommandSort3">
              <span style="color:#333333;" class="el-dropdown-link">
                {{discountTxt}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu style="margin-top:10rem;" slot="dropdown">
                <el-dropdown-item class="dropdown-list" command="折扣比例">折扣比例</el-dropdown-item>
                <el-dropdown-item class="dropdown-list" command="修改总价">修改总价</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- <input v-if="discountTxt == '折扣比例'" placeholder="请输折扣比例" type="text" v-model="discountRatio"> -->
          <div v-if="discountTxt == '折扣比例'"
            style="padding:0 10rem;height:36rem;display:flex;align-items:center;border: 1rem solid #E6E6E6;justify-content: center;margin:0 10rem;">
            <el-dropdown trigger="click" @command="handleCommandSort5">
              <span style="color:#333333;" class="el-dropdown-link">
                {{discountRatio == ''?'请选择折扣比例':discountRatio}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu style="margin-top:10rem;width:120px;" slot="dropdown">
                <el-dropdown-item v-for="(item,index) in discountRatioList" :key="index" class="dropdown-list"
                  :command="item">{{item.remark}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <input v-if="discountTxt == '修改总价'" placeholder="请输总价" type="text" v-model="discountPrice">
          <!-- <p style="font-size:16rem;color:#333333;" v-if="discountTxt == '折扣比例'">%</p> -->
        </div>
        <div v-if="discountTxt == '修改总价'" class="masking-cash-keyboard">
          <div class="list">
            <p class="list-detail" @click="keyBoard(4,1)">1</p>
            <p class="list-detail" @click="keyBoard(4,2)">2</p>
            <p class="list-detail" @click="keyBoard(4,3)">3</p>
          </div>
          <div class="list" style="margin-top:12rem;">
            <p class="list-detail" @click="keyBoard(4,4)">4</p>
            <p class="list-detail" @click="keyBoard(4,5)">5</p>
            <p class="list-detail" @click="keyBoard(4,6)">6</p>
          </div>
          <div class="list" style="margin-top:12rem;">
            <p class="list-detail" @click="keyBoard(4,7)">7</p>
            <p class="list-detail" @click="keyBoard(4,8)">8</p>
            <p class="list-detail" @click="keyBoard(4,9)">9</p>
          </div>
          <div class="list" style="margin-top:12rem;">
            <p class="list-detail" @click="keyBoard(4,'.')" style="line-height:33rem;">.</p>
            <p class="list-detail" @click="keyBoard(4,0)">0</p>
            <div class="list-detail" @click="deleteInput(4)"> <img src="../assets/images/deleteIcon2.png" alt=""> </div>
          </div>
        </div>
        <p class="btn" @click="discountClick()">确定</p>
      </div>
      <!-- 代寄代发 -->
      <div v-if="maskType == 6" style="width:100%;height:100%;position:fixed;z-index:1002;top:0;left:0;overflow:auto;">
        <div class="deduction" style="position:static;margin:100rem auto;">
          <div class="header">
            <p>代寄代发</p>
            <img @click="payClick(0)" src="../assets/images/closeIcon2.png" alt="">
          </div>
          <div class="list">
            <p class="title">代寄代发开关：</p>
            <div @click="replaceSwitck()" class="switch" :style="replace?'':'background:#D7DBE0;'">
              <div class="switch-line" :style="replace?'margin-left: 26rem;':'margin-left: 2rem;'"></div>
            </div>
          </div>
          <div class="list">
            <p class="title">收货信息：</p>
            <div class="add" @click="addAddress()">+ 新增收货地址</div>
          </div>
          <!-- 收货地址信息 -->
          <div v-for="(item,index) in addressList" :key="index" style="margin-top:12rem;" class="list">
            <p class="title"></p>
            <div class="address">
              <!-- <img src="" class="address-icon" alt=""> -->
              <img @click="defaultClick(item.id)" v-if="item.state == 1" src="../assets/images/addressChoice.png"
                class="address-icon" alt="">
              <img @click="defaultClick(item.id)" v-if="item.state == 0" src="../assets/images/addressChoice2.png"
                class="address-icon" alt="">
              <div class="detail">
                <p>{{item.linkman}} {{item.phone}}</p>
                <p>{{item.address}} {{item.addressDetail}}</p>
              </div>
              <div class="delete">
                <img @click="deleteAddress(item.id)" src="../assets/images/deleteIcon.png" alt="">
              </div>
              <img @click="editAddress(index)" src="../assets/images/editIcon.png" class="edit" alt="">
            </div>
          </div>
          <div style="margin-top:14rem;" class="list">
            <p class="title">发货方式：</p>
            <p class="deliver" @click="deliverClick(index)" :class="{activite:index == deliverType}"
              v-for="(item,index) in deliver" :key="index">{{item}}</p>
          </div>
          <!-- 他店代发 -->
          <div style="margin-top:14rem;" class="list">
            <p class="title"></p>
            <div class="shop" v-if="shopList != null && shopList != ''">
              <div class="list-top">
                <p class="title">门店名称</p>
                <p class="line"></p>
                <p class="name">操作</p>
              </div>
              <div v-for="(item,index) in shopList" :key="index" class="list-box">
                <p v-if="item.stock > 0" class="choice" :class="{activiteChoice:index == stockIndex}"
                  @click="stockSee(index,item.erpShopNo)"><span v-if="index === stockIndex"></span></p>
                <p v-if="item.stock <= 0" class="choice sellOut"><span></span></p>
                <p v-if="item.stock > 0" class="title" @click="stockSee(index,item.erpShopNo)">{{item.storeName}}</p>
                <p v-if="item.stock <= 0" class="title" style="color:#999999;">{{item.storeName}}</p>
                <p class="line"></p>
                <p class="name" @click="clickStock(item.erpShopId)" v-if="item.stock > 0">查看</p>
              </div>
            </div>
          </div>
          <p class="confirm" @click="sendGoods()">确认</p>
        </div>
      </div>
      <!-- 积分抵扣 -->
      <div v-if="maskType == 7" class="integration">
        <div class="header">
          <p>积分抵扣</p>
          <img @click="payClick(0)" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <div class="list">
          <p class="title">会员可用积分</p>
          <p class="integral"> {{this.memberContent.memberPoints}}points</p>
        </div>
        <p class="msg">（1积分抵扣1元，满1元可用，单笔订单最高抵扣100%）</p>
        <p class="line"></p>
        <div class="list">
          <p class="title">使用积分：</p>
          <input v-model="integralInput" type="number" class="use">
        </div>
        <div class="list" style="margin-top:20rem;">
          <p class="title">抵扣金额：</p>
          <p style="font-size:14rem;color:#333333;margin-left:16rem;">{{integralInput == ''?'0':integralInput}}元</p>
        </div>
        <p class="confirm" @click="integralConfirm()">确认</p>
      </div>
      <!-- 开发票 -->
      <div v-if="maskType == 8" class="invoice">
        <div class="header">
          <p>开发票</p>
          <img @click="payClick(0)" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <div style="margin-top:38rem;" class="list">
          <p class="title">选择类型：</p>
          <p class="type">电子普通发票</p>
          <p class="type" style="border:1rem solid #D9D9D9;width:124rem;">增值税专用发票</p>
        </div>
        <div style="margin-top:18rem;" class="list">
          <p class="title">抬头类型：</p>
          <p @click="riseBtn(1)" class="choice" :class="{activite:riseType == 1}"> <span v-if="riseType == 1"></span>
          </p>
          <p @click="riseBtn(1)" class="rise">个人</p>
          <p @click="riseBtn(2)" class="choice" :class="{activite:riseType == 2}"> <span v-if="riseType == 2"></span>
          </p>
          <p @click="riseBtn(2)" class="rise">企业</p>
        </div>
        <!-- 个人抬头 -->
        <div v-if="riseType == 1">
          <div style="margin-top:18rem;" class="list">
            <p class="title">抬头名称：</p>
            <input type="text" v-model="invoice.name" placeholder="请输入抬头名称">
          </div>
          <!-- <div class="line"></div> -->
          <div style="margin-top:18rem;" class="list">
            <p class="title">电子邮箱：</p>
            <input type="text" v-model="invoice.email" placeholder="请输入要发送的邮箱">
          </div>
          <div style="margin-top:18rem;" class="list">
            <p class="title">手机号码：</p>
            <input type="text" v-model="invoice.phone" placeholder="请输入手机号码">
          </div>
        </div>
        <!-- 企业抬头 -->
        <div v-if="riseType == 2">
          <div style="margin-top:18rem;" class="list">
            <p class="title">抬头名称：</p>
            <input type="text" v-model="invoice.name" placeholder="请输入抬头名称">
          </div>
          <div style="margin-top:18rem;" class="list">
            <p class="title">单位税号：</p>
            <input type="text" v-model="invoice.dutyParagraph" placeholder="请输入单位税号">
          </div>
          <!-- <div class="more">更多选项</div>
          <div class="line"></div> -->
          <div class="list" style="margin-top:18rem;">
            <p class="title">电子邮箱：</p>
            <input type="text" v-model="invoice.email" placeholder="请输入要发送的邮箱">
          </div>
          <div style="margin-top:18rem;" class="list">
            <p class="title">手机号码：</p>
            <input type="text" v-model="invoice.phone" placeholder="请输入手机号码">
          </div>
        </div>
        <p class="confirm" @click="invoiceConfirm()">确认</p>
      </div>
      <!-- 选择会员 -->
      <div v-if="maskType == 9" class="choiceMember">
        <div class="header">
          <p>选择会员</p>
          <img @click="payClick(0)" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <div class="list">
          <p class="title" style="width:100rem;">会员编号 ：</p>
          <input v-model="memberCode" type="text" class="input" placeholder="请扫描/输入会员编号">
          <!-- <p @click="scanCode()" style="width:50rem;height:32rem;text-align:center;background:rgb(0, 40, 28);color:#fff;line-height:32rem;margin-left:10rem;">扫描</p> -->
          <!-- <input id="pictureB_file" accept="image/*" type="file" capture="camera" name="pictureA_file"/> -->
        </div>
        <div class="list">
          <p class="title" style="width:100rem;">手机号：</p>
          <input type="text" v-model="memnerPhone" class="input" placeholder="请输入手机号">
        </div>
        <div class="footerList">
          <p class="btn" @click="addMember()">新增会员</p>
          <p class="btn" @click="queryMember()" style="background:#00281C;margin-left:16rem;">确认</p>
        </div>
      </div>
      <!-- 选择导购 -->
      <div v-if="maskType == 10" class="guide">
        <div class="header">
          <p>选择导购</p>
          <img @click="payClick(0)" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <div class="list">
          <p class="title">选择类型：</p>
          <p class="type" @click="guideBtn(1)" :class="{typeActivite:guideType == 1}">独立销售</p>
          <p class="type" @click="guideBtn(2)" :class="{typeActivite:guideType == 2}">共同销售</p>
          <p class="type" @click="guideBtn(3)" :class="{typeActivite:guideType == 3}">协助销售</p>
        </div>
        <!-- 独立销售 -->
        <div v-if="guideType == 1" class="list" style="margin-top:18rem;margin-bottom:46rem;">
          <p class="title"> <span style="color:#BE0000;">*</span>导购员1：</p>
          <div style="width:226rem;height:32rem;border:1rem solid #D9D9D9;border-radius:2rem;padding:0 12rem;">
            <el-dropdown trigger="click" @command="handleCommandSort4">
              <p class="el-dropdown-link" style=";display:flex;width:226rem;align-items:center;height:32rem;">
                <span style="flex:1;display: inline-block;">{{independent}}</span>
                <i class="el-icon-arrow-down el-icon--right;margin-right:12rem;"></i>
              </p>
              <el-dropdown-menu style="margin-top:10rem;width:226rem;" slot="dropdown">
                <el-dropdown-item v-for="(item,index) in shopGuideList" v-bind:key="index" class="dropdown-list"
                  :command="composeValue(item.nickName,item.userId,1)">{{item.nickName}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <p style="margin-left:10rem;color:red;">100%</p>
        </div>
        <div v-else>
          <div class="list" style="margin-top:18rem;">
            <p class="title"> <span style="color:#BE0000;">*</span>导购员1：</p>
            <div style="width:226rem;height:32rem;border:1rem solid #D9D9D9;border-radius:2rem;padding:0 12rem;">
              <el-dropdown trigger="click" @command="handleCommandSort4">
                <p class="el-dropdown-link" style=";display:flex;width:226rem;align-items:center;height:32rem;">
                  <span style="flex:1;display: inline-block;">{{independent}}</span>
                  <i class="el-icon-arrow-down el-icon--right;margin-right:12rem;"></i>
                </p>
                <el-dropdown-menu style="margin-top:10rem;width:226rem;" slot="dropdown">
                  <el-dropdown-item v-for="(item,index) in shopGuideList" v-bind:key="index" class="dropdown-list"
                    :command="composeValue(item.nickName,item.userId,1)">{{item.nickName}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <p style="margin-left:10rem;color:red;">{{guideType == 2?'50%':'80%'}}</p>
          </div>
          <div class="list" style="margin-top:18rem;margin-bottom:46rem">
            <p class="title"> <span style="color:#BE0000;">*</span>导购员2：</p>
            <div style="width:226rem;height:32rem;border:1rem solid #D9D9D9;border-radius:2rem;padding:0 12rem;">
              <el-dropdown trigger="click" @command="handleCommandSort4">
                <p class="el-dropdown-link" style=";display:flex;width:226rem;align-items:center;height:32rem;">
                  <span style="flex:1;display: inline-block;">{{independent2}}</span>
                  <i class="el-icon-arrow-down el-icon--right;margin-right:12rem;"></i>
                </p>
                <el-dropdown-menu style="margin-top:10rem;width:226rem;" slot="dropdown">
                  <el-dropdown-item v-for="(item,index) in shopGuideList" v-bind:key="index" class="dropdown-list"
                    :command="composeValue(item.nickName,item.userId,2)">{{item.nickName}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <p style="margin-left:10rem;color:red;">{{guideType == 2?'50%':'20%'}}</p>
          </div>
        </div>
        <p class="confirm" @click="confirmGuide()">确认</p>
      </div>
    </div>
    <!-- 新增会员 -->
    <div v-if="addMemberStatu == 1">
      <div class="layer" @click="closeAssmember()" style="z-index:2000;"></div>
      <div class="addMember">
        <div style="position:relative;">
          <div class="header">
            <p>新增会员</p>
            <img @click="closeAssmember()" src="../assets/images/closeIcon2.png" alt="">
          </div>
          <div style="max-height: 400rem;overflow-y: auto;padding-top:64rem;">
            <div class="addMember-list">
              <p class="name"><span style="color:#f5222d;font-size:16rem;">*</span>会员名称</p>
              <input class="input" v-model="memberDetail.memberName" placeholder="请输入会员名称">
            </div>
            <div class="addMember-list">
              <p class="name">入会门店</p>
              <!-- <input class="input" v-model="memberDetail.storeName" placeholder="请输入入会门店"> -->
              <div class="por">
                <div class="selectBox">
                  <p class="input" v-on:click.stop="arrowDown(4)" title="请选择">{{memberDetail.storeName}}</p>
                  <div class="selectBox_list" v-show="isShowSelect == 4">
                    <div class="selectBox_listLi" v-for="(item, index) in sysStoreList" :key="index"
                      @click.stop="select(item.storeName, index,item.id)">{{item.storeName}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="addMember-list">
              <p class="name"><span style="color:#f5222d;font-size:16rem;">*</span>导购员</p>
              <div class="por">
                <div class="selectBox">
                  <p class="input" v-on:click.stop="arrowDown(1)" title="请选择">{{memberDetail.userName}}</p>
                  <div class="selectBox_list" v-show="isShowSelect == 1">
                    <div class="selectBox_listLi" v-for="(item, index) in shopGuideList" :key="index"
                      @click.stop="select(item.nickName, index)">{{item.nickName}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="addMember-list">
              <p class="name"><span style="color:#f5222d;font-size:16rem;">*</span>手机号</p>
              <input class="input" v-model="memberDetail.phone" placeholder="请输入手机号码">
            </div>
            <div class="addMember-list">
              <p class="name"><span style="color:#f5222d;font-size:16rem;">*</span>会员等级</p>
              <div class="por">
                <div class="selectBox">
                  <p class="input" v-on:click.stop="arrowDown(2)" title="请选择">{{levelTitle}}</p>
                  <div class="selectBox_list" v-show="isShowSelect == 2">
                    <div class="selectBox_listLi" v-for="(item, index) in levelList" :key="index"
                      @click.stop="select(item, index)">{{item.value}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="addMember-list">
              <p class="name" style="margin-right:18rem;"><span style="color:#f5222d;font-size:16rem;"></span>性别</p>
              <el-radio v-model="memberDetail.gender" label="1" style="margin-right:20rem;">男</el-radio>
              <el-radio v-model="memberDetail.gender" label="2" style="margin-right:20rem;">女</el-radio>
              <el-radio v-model="memberDetail.gender" label="0" style="margin-right:20rem;">未知</el-radio>
            </div>
            <div class="addMember-list">
              <p class="name">真实姓名</p>
              <input class="input" v-model="memberDetail.name" placeholder="请输入真实姓名">
            </div>
            <div class="addMember-list">
              <p class="name">证件类型</p>
              <div class="por">
                <div class="selectBox">
                  <p class="input" v-on:click.stop="arrowDown(3)" title="请选择">{{credentialName}}</p>
                  <div class="selectBox_list" v-show="isShowSelect == 3">
                    <div class="selectBox_listLi" v-for="(item, index) in credentiaList" :key="index"
                      @click.stop="select(item, index)">{{item.value}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="addMember-list">
              <p class="name">证件号</p>
              <input class="input" v-model="memberDetail.idNum" placeholder="请输入证件号码">
            </div>
            <div class="addMember-list">
              <p class="name">生日</p>
              <div class="block">
                <el-date-picker class="meeting-date" value-format="yyyy-MM-dd HH:mm:ss" v-model="memberDetail.birthday"
                  type="date" placeholder="选择日期">
                </el-date-picker>
              </div>
            </div>
            <div class="addMember-list">
              <p class="name">备注</p>
              <input class="input" v-model="memberDetail.remark" placeholder="请输入内容">
            </div>
          </div>
          <p class="addMember-btn" @click="addMemberClick()">确认</p>
        </div>
      </div>
    </div>
    <!-- 新增收货地址 -->
    <div v-if="addAdressStatu == 1">
      <div @click="closeAddress()" class="layer" style="z-index:1009;"></div>
      <div class="addAdress">
        <div class="header">
          <p>代寄代发</p>
          <img @click="closeAddress()" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <div class="addMember-list">
          <p class="name"><span style="color:#f5222d;">* </span>省市区 </p>
          <el-cascader :options="optionsAddress" :placeholder="editAddressDetail.address" clearable
            v-model="editAddressDetail.address"></el-cascader>
        </div>
        <div class="addMember-list">
          <p class="name"><span style="color:#f5222d;">* </span>详细地址 </p>
          <input class="input" placeholder="请输入详细地址" v-model="editAddressDetail.addressDetail">
        </div>
        <div class="addMember-list">
          <p class="name"><span style="color:#f5222d;">* </span>收件人 </p>
          <input class="input" placeholder="请输入收件人" v-model="editAddressDetail.linkman">
        </div>
        <div class="addMember-list">
          <p class="name"><span style="color:#f5222d;">* </span>联系方式 </p>
          <input class="input" placeholder="请输入联系方式 " v-model="editAddressDetail.phone">
        </div>
        <p class="preservationAddress" @click="preservationAddress()">保存</p>
      </div>
    </div>
    <!-- 添加备注 -->
    <div v-if="maskType == 11">
      <div class="addAdress">
        <div class="header">
          <p>添加备注</p>
          <img @click="payClick(0)" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <div>
          <el-input type="textarea" :rows="2" placeholder="请输入备注信息" v-model="textarea">
          </el-input>
        </div>
        <div class="btnclass">
          <el-button @click="payClick(0)" type="info" style="width:100rem;height:40rem;border-radius:4rem;">取消
          </el-button>
          <el-button @click="editMsg()" type="primary"
            style="width:100rem;height:40rem;border-radius:4rem;margin-left:40rem;">确认</el-button>
        </div>
      </div>
    </div>
    <!-- 挂单列表 -->
    <div v-if="hangingStatu == 1">
      <div class="layer" @click="closeHanging()" style="z-index:1000;"></div>
      <div class="hanging">
        <div class="box">
          <div class="header">
            <p>挂单列表</p>
            <img @click="closeHanging()" src="../assets/images/closeIcon2.png" alt="">
          </div>
          <div class="detail">
            <!-- 手机号搜索 -->
            <div class="search">
              <p>手机号: </p>
              <input type="num" v-model="searchPhone" class="search-input">
              <p class="search-btn" @click="hangingSearch()">搜索</p>
            </div>
            <!-- 列表 -->
            <div class="list">
              <div class="list-top">
                <p class="list-top-title" style="width:100rem;">序号</p>
                <p class="list-top-title" style="width:150rem;">客户</p>
                <p class="list-top-title" style="width:150rem;">商品数量</p>
                <p class="list-top-title" style="width:150rem;">金额</p>
                <p class="list-top-title" style="width:220rem;">挂起时间</p>
                <p class="list-top-title" style="width:220rem;">备注</p>
                <p class="list-top-title" style="flex:1;">操作</p>
              </div>
              <div class="list-box">
                <div v-for="(item,i) in hangingList" :key='i' class="list-order" style="height:auto;">
                  <div class="list-top-title" style="width:100rem;">{{i+1}}</div>
                  <p class="list-top-title" style="width:150rem;">{{item.memberName}}</p>
                  <p class="list-top-title" style="width:150rem;">{{item.goodsNum}}</p>
                  <p class="list-top-title" style="width:150rem;">{{item.totalPrice}}</p>
                  <p class="list-top-title" style="width:220rem;">{{item.createTime}}</p>
                  <p class="list-top-title" style="width:220rem;">{{item.remark}}</p>
                  <div class="list-top-title" style="flex:1;display:flex;align-items:center;justify-content: center;">
                    <p style="color:#1890ff;" @click="deleteHanging(item.id)">删除</p>
                    <p style="width:1rem;height:16rem;background:#e8e8e8;margin:0 8rem;"></p>
                    <p style="color:#1890ff;" @click="takeHanging(i)">取单</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 指定门店的商品库存 -->
    <div v-if="storeGoodsStatu == 1">
      <div class="layer" @click="closeStoreGoods()" style="z-index:1040;"></div>
      <div class="stock">
        <div class="header">
          <p>店铺库存</p>
          <img @click="closeStoreGoods()" src="../assets/images/closeIcon2.png" alt="">
        </div>
        <div class="detail">
          <div class="detail-top">
            <p class="goods">商品</p>
            <p class="detail-stock">库存</p>
          </div>
          <div class="stock-box">
            <div v-for="(item,index) in storeGoodsStock" :key="index" class="detail-goods">
              <div class="detail-content">
                <p style="margin-left:20rem;">商品：{{item.goodsName}}</p>
                <p style="margin-left:20rem;margin-top:10rem;">规格：{{item.specName}}</p>
              </div>
              <p class="detail-goods-stock">{{item.stock}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  memberDetail,
  addMember,
  checkAddress,
  defaultAddress,
  addAddress,
  deletememberAddress,
  findByStoreId,
  getBySpecNo,
  addOrder,
  hangingList,
  addOrderDetail,
  editOrderDetail,
  delelteOrderDetail,
  delelteOrder,
  findByOrderId,
  addorderGuide,
  updateOrders,
  findDeliverGoodsStock,
  findStoreGoodsStock,
  orderPay,
  queryCityTree,
  sysStore,
  discountRatio,
  findByUserId
} from '../until/user.js'
export default {
  props: {},
  // components: { headers },
  data () {
    return {
      userDetail: '',
      // barcode: '',
      recordHeight: {
        height: '30rem',
        overflow: 'hidden'
      },
      recordStatu: 0,
      textarea: '',
      footerList: ['整单折扣', '代寄代发', '积分抵扣', '开发票', '添加备注'],
      footerIndex: -1,
      scanningNum: '',
      // 1-pos 2-现金 3-押金 4-组合支付 5-整单折扣 6-代寄代发 7-积分抵扣 8-开发票 9-选择会员 10-选择导购
      maskType: 0,
      combination1: 'POS支付',
      combination2: '现金支付',
      discountTxt: '折扣比例',
      replace: false,
      // 发货方式
      deliver: ['本店代寄', '他店代发', '总仓代发'],
      deliverType: 0,
      deliverGoodsShop: '',
      // 他店代发
      replaceType: 0,
      stockIndex: -1,
      storeGoodsStock: '',
      storeGoodsStatu: 0,
      // 抬头类型
      riseType: 1,
      // 选择导购
      guideType: 1,
      // 导购1
      independent: '请选择',
      independentId: '',
      // 导购2
      independent2: '请选择',
      independentId2: '',
      independentType: 0,
      // 新增会员
      addMemberStatu: 0,
      joinPromoPrice: 0,
      memberDetail: {
        memberName: '',
        storeName: '请选择入会门店',
        userName: '请选择导购员',
        phone: '',
        gender: '1',
        level: '',
        name: '',
        credentialType: '',
        idNum: '',
        birthday: '',
        remark: ''
      },
      // 会员等级
      levelTitle: '请选择会员等级',
      // 会员等级列表
      levelList: [{
        key: 1,
        value: '粉丝'
      },
      {
        key: 2,
        value: '会员'
      },
      {
        key: 3,
        value: 'VIP会员'
      },
      {
        key: 4,
        value: '亲友会员'
      }
      ],
      // 证件类型
      credentialName: '请选择证件类型',
      credentiaList: [{
        key: 0,
        value: '身份证'
      },
      {
        key: 1,
        value: '护照'
      }
      ],
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      isShowSelect: 0,
      dataList: [{
        key: 0,
        value: '苹果'
      },
      {
        key: 1,
        value: '香蕉'
      },
      {
        key: 2,
        value: '荔枝'
      },
      {
        key: 3,
        value: '火龙果'
      },
      {
        key: 4,
        value: '橘子'
      },
      {
        key: 5,
        value: '黄瓜'
      }
      ],
      // 导购员
      shopGuide: [{
        key: 0,
        value: '导购一'
      },
      {
        key: 1,
        value: '导购二'
      },
      {
        key: 2,
        value: '导购三'
      }
      ],
      unitName: '请选择',
      // 新增收货地址
      addAdressStatu: 0,
      addAdressType: 0,
      optionsAddress: [{
        value: '指南',
        label: '指南',
        children: [{
          value: '设计原则',
          label: '设计原则',
          children: [{
            value: '一致',
            label: '一致'
          }, {
            value: '反馈',
            label: '反馈'
          }, {
            value: '效率',
            label: '效率'
          }, {
            value: '可控',
            label: '可控'
          }]
        }, {
          value: '导航',
          label: '导航',
          children: [{
            value: '侧向导航',
            label: '侧向导航'
          }, {
            value: '顶部导航',
            label: '顶部导航'
          }]
        }]
      }, {
        value: '组件',
        label: '组件',
        children: [{
          value: 'Basic',
          label: 'Basic',
          children: [{
            value: 'Layout 布局',
            label: 'Layout 布局'
          }, {
            value: 'Color 色彩',
            label: 'Color 色彩'
          }, {
            value: 'Typography 字体',
            label: 'Typography 字体'
          }, {
            value: 'Icon 图标',
            label: 'Icon 图标'
          }, {
            value: 'Button 按钮',
            label: 'Button 按钮'
          }]
        }, {
          value: 'Form',
          label: 'Form',
          children: [{
            value: 'Radio 单选框',
            label: 'Radio 单选框'
          }, {
            value: 'Checkbox 多选框',
            label: 'Checkbox 多选框'
          }, {
            value: 'Input 输入框',
            label: 'Input 输入框'
          }, {
            value: 'InputNumber 计数器',
            label: 'InputNumber 计数器'
          }, {
            value: 'Select 选择器',
            label: 'Select 选择器'
          }, {
            value: 'Cascader 级联选择器',
            label: 'Cascader 级联选择器'
          }, {
            value: 'Switch 开关',
            label: 'Switch 开关'
          }, {
            value: 'Slider 滑块',
            label: 'Slider 滑块'
          }, {
            value: 'time-picker',
            label: 'TimePicker 时间选择器'
          }, {
            value: 'date-picker',
            label: 'DatePicker 日期选择器'
          }, {
            value: 'datetime-picker',
            label: 'DateTimePicker 日期时间选择器'
          }, {
            value: 'upload',
            label: 'Upload 上传'
          }, {
            value: 'rate',
            label: 'Rate 评分'
          }, {
            value: 'form',
            label: 'Form 表单'
          }]
        }, {
          value: 'data',
          label: 'Data',
          children: [{
            value: 'table',
            label: 'Table 表格'
          }, {
            value: 'tag',
            label: 'Tag 标签'
          }, {
            value: 'progress',
            label: 'Progress 进度条'
          }, {
            value: 'tree',
            label: 'Tree 树形控件'
          }, {
            value: 'pagination',
            label: 'Pagination 分页'
          }, {
            value: 'badge',
            label: 'Badge 标记'
          }]
        }, {
          value: 'notice',
          label: 'Notice',
          children: [{
            value: 'alert',
            label: 'Alert 警告'
          }, {
            value: 'loading',
            label: 'Loading 加载'
          }, {
            value: 'message',
            label: 'Message 消息提示'
          }, {
            value: 'message-box',
            label: 'MessageBox 弹框'
          }, {
            value: 'notification',
            label: 'Notification 通知'
          }]
        }, {
          value: 'navigation',
          label: 'Navigation',
          children: [{
            value: 'menu',
            label: 'NavMenu 导航菜单'
          }, {
            value: 'tabs',
            label: 'Tabs 标签页'
          }, {
            value: 'breadcrumb',
            label: 'Breadcrumb 面包屑'
          }, {
            value: 'dropdown',
            label: 'Dropdown 下拉菜单'
          }, {
            value: 'steps',
            label: 'Steps 步骤条'
          }]
        }, {
          value: 'others',
          label: 'Others',
          children: [{
            value: 'dialog',
            label: 'Dialog 对话框'
          }, {
            value: 'tooltip',
            label: 'Tooltip 文字提示'
          }, {
            value: 'popover',
            label: 'Popover 弹出框'
          }, {
            value: 'card',
            label: 'Card 卡片'
          }, {
            value: 'carousel',
            label: 'Carousel 走马灯'
          }, {
            value: 'collapse',
            label: 'Collapse 折叠面板'
          }]
        }]
      }, {
        value: 'ziyuan',
        label: '资源',
        children: [{
          value: 'axure',
          label: 'Axure Components'
        }, {
          value: 'sketch',
          label: 'Sketch Templates'
        }, {
          value: 'jiaohu',
          label: '组件交互文档'
        }]
      }],
      // 添加备注
      remarksStatu: 1,
      // 根据会员编号/手机号查询会员信息
      memberCode: '',
      memnerPhone: '',
      memberContent: '',
      memberPrice: 0,
      // 现金支付
      cashReceivable: 330,
      cashPay: '',
      cashGivechange: '',
      // 押金
      deposit: '',
      // 组合支付
      combinationPay1: '',
      combinationPay2: '',
      inputFocusIndex: '',
      // 整单折扣
      discountRatio: '',
      discountRatio2: '',
      discountPrice: '',
      discountStatu: 0,
      // 会员收货地址
      addressList: [],
      editAddressDetail: {},
      // 店铺导购员列表
      shopGuideList: [],
      // 订单----
      orderList: [],
      orderId: '',
      // 0-取单 1-挂单
      hangingType: 0,
      // 挂单
      hangingStatu: 0,
      hangingList: [],
      // 整单折扣
      wholeOrderPrice: 0,
      // 积分抵扣
      integralOrderPrice: 0,
      // 商品总数
      goodsTotalNum: 0,
      localPrice: 0, // 原价
      // 合计金额
      totalAmountPrice: 0,
      totalAmountPrice2: 0,
      totalAmountPrice3: 0,
      // 挂单列表手机号搜索
      searchPhone: '',
      shopList: [],
      // 使用积分
      integralPrice: 0,
      integralInput: '',
      // 发票信息
      invoice: {
        name: '',
        dutyParagraph: '',
        email: '',
        headerType: this.riseType,
        phone: ''
      },
      // 地区信息
      cityTreeList: [],
      // 入会门店列表
      sysStoreList: [],
      // 折扣比例
      discountRatioList: []
    }
  },
  computed: {

  },
  created () {

  },
  mounted () {
    if (this.$route.params.barcode !== undefined) {
      this.memberCode = this.$route.params.barcode
      this.maskType = 9
    }
    console.log('获取账号信息')
    // var userDetail = sessionStorage.getItem('userDetail')
    // userDetail = eval('(' + userDetail + ')')
    // this.userDetail = userDetail
    // console.log(userDetail)
    var orderList = JSON.parse(sessionStorage.getItem('orderList'))
    var orderId = sessionStorage.getItem('orderId')
    // console.log(sessionStorage.getItem('independent'))
    if (sessionStorage.getItem('independent')) {
      this.independent = sessionStorage.getItem('independent')
      this.independentType = 1
    }
    if (sessionStorage.getItem('independent2')) {
      this.independent2 = sessionStorage.getItem('independent2')
    }
    if (orderList) {
      this.orderList = orderList
      this.sunNum()
    }
    if (orderId && orderList) {
      this.orderId = orderId
      this.hangingType = 1
    }
    return new Promise((resolve, reject) => {
      var memberContent = JSON.parse(sessionStorage.getItem('memberContent'))
      if (memberContent) {
        this.memberContent = memberContent
        this.memberAddress(this.memberContent.id)
        this.sunNum()
      }
      // 查询门店导购
      findByStoreId()
        .then(response => {
          if (response.data.code === 200) {
            this.shopGuideList = response.data.data
            this.shopGuideList.forEach(item => {
              if (item.userId === this.userDetail.userId) {
                this.memberDetail.userName = item.username
              }
            })
          } else {
            this.$message({
              message: response.data.msg,
              type: 'warning'
            })
            return
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
        // 查询挂单列表
      hangingList('')
        .then(response => {
          if (response.data.code === 200) {
            this.hangingList = response.data.data
          } else {
            this.$message({
              message: response.data.msg,
              type: 'warning'
            })
            return
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
        // 查询地区信息
      queryCityTree()
        .then(response => {
          if (response.data.code === 200) {
            this.cityTreeList = response.data.data
            var optionsAddress = []
            response.data.data.forEach(item => {
              var arr = {}
              arr.value = item.name
              arr.label = item.name
              var children3 = {}
              var children = []
              var arr5 = []
              item.list.forEach(i => {
                // console.log('iii')
                // console.log(i)
                var children2 = {}
                var arr2 = []
                // var list = {}
                if (i.list.length > 0) {
                  children2.value = i.name
                  children2.label = i.name
                  i.list.forEach(a => {
                    var list2 = {}
                    list2.value = a.name
                    list2.label = a.name
                    arr2.push(list2)
                  })
                  children2.children = arr2
                  children.push(children2)
                  arr.children = children
                } else {
                  children3.value = item.name
                  children3.label = item.name
                  var list3 = {
                    value: i.name,
                    label: i.name
                  }
                  arr5.push(list3)
                  // children3.children = arr2
                  // children.push(children3)
                  // arr.children = children
                }
              })
              // console.log(arr5)
              if (children3.value !== undefined) {
                // console.log('进入')
                children3.children = arr5
                children.push(children3)
                arr.children = children
              }
              optionsAddress.push(arr)
            })
            // console.log('打印--')
            // console.log(optionsAddress)
            this.optionsAddress = optionsAddress
          } else {
            this.$message({
              message: response.data.msg,
              type: 'warning'
            })
            return
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
        // 查询折扣比例
      discountRatio()
        .then(response => {
          if (response.data.code === 200) {
            this.discountRatioList = response.data.data
          } else {
            this.$message({
              message: response.data.msg,
              type: 'warning'
            })
            return
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
      findByUserId()
        .then(response => {
          if (response.data.code === 200) {
            // console.log('进入')
            this.userDetail = response.data.data
            sessionStorage.setItem('userDetail', JSON.stringify(response.data.data))
          } else {
            this.$message({
              message: response.data.msg,
              type: 'warning'
            })
            return
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  methods: {
    footerClick (index) {
      this.footerIndex = index
      if (index === 0) {
        this.maskType = 5
      } else if (index === 1) {
        if (this.memberContent === '') {
          this.footerIndex = -1
          this.$message({
            message: '请选择会员',
            type: 'warning'
          })
          return
        }
        this.maskType = 6
        if (this.shopList.length === 0) {
          return new Promise((resolve, reject) => {
            findDeliverGoodsStock(this.orderId, 4)
              .then(response => {
                if (response.data.code === 200) {
                  this.shopList = response.data.data
                } else {
                  this.$message({
                    message: response.data.msg,
                    type: 'warning'
                  })
                  return
                }
                resolve()
              })
              .catch(error => {
                reject(error)
              })
          })
        }
      } else if (index === 2) {
        if (this.memberContent === '') {
          this.footerIndex = -1
          this.$message({
            message: '请选择会员',
            type: 'warning'
          })
          return
        }
        if (this.orderList.findIndex(target => target.isJoinPromo === 1) == -1) {
          this.$message({
            message: '该产品设置了不曾参与活动、不可使用积分',
            type: 'warning'
          })
          setTimeout(() => {
            this.footerIndex = -1
          }, 1000)
          return false
        }

        if (this.integralInput === null || this.integralInput === '' || this.integralInput === 0) {
          if (this.memberContent.memberPoints > 0) {
            var totalPoints2 = this.joinPromoPrice - this.memberPrice
            this.integralPrice = this.memberContent.memberPoints
            if (this.integralPrice > totalPoints2) {
              this.integralPrice = totalPoints2
            } else {
              this.integralPrice = this.integralPrice
            }
          }
          this.integralInput = this.integralPrice
          // console.log(this.integralPrice,'this.integralPrice')
          // console.log(this.memberContent.memberPoints,'this.memberContent.memberPoints')
        }
        this.maskType = 7
      } else if (index === 3) {
        if (this.memberContent === '') {
          this.footerIndex = -1
          this.$message({
            message: '请选择会员',
            type: 'warning'
          })
          return
        }
        this.maskType = 8
      } else if (index === 4) {
        this.maskType = 11
      }
    },
    handleCommandSort: function (e) {
      this.combination1 = e
    },
    handleCommandSort2: function (e) {
      this.combination2 = e
    },
    handleCommandSort3: function (e) {
      this.discountTxt = e
    },
    handleCommandSort4: function (command) {
      if (command.type === 1) {
        if (command.id === this.independentId2) {
          this.$message({
            message: '导购员不能重复',
            type: 'warning'
          })
          return
        }
        this.independent = command.nickName
        this.independentId = command.id
      } else if (command.type === 2) {
        if (command.id === this.independentId) {
          this.$message({
            message: '导购员不能重复',
            type: 'warning'
          })
          return
        }
        this.independent2 = command.nickName
        this.independentId2 = command.id
      }
    },
    handleCommandSort5: function (command) {
      // console.log(command)
      this.discountRatio = command.remark
      this.discountRatio2 = command.ratio
    },
    payClick (e) {
      if (this.maskType === 7) {
        // this.totalAmountPrice2 = parseInt(this.totalAmountPrice2 + this.integralInput)
        this.integralInput = ''
      }
      this.maskType = e
      this.footerIndex = -1
      this.memberCode = ''
      this.memnerPhone = ''
    },
    // 代寄代发-发货方式
    deliverClick (e) {
      this.deliverType = e
      this.shopList = ''
      if (e === 1) {
        // 他店代发
        this.replaceType = 1
        return new Promise((resolve, reject) => {
          findDeliverGoodsStock(this.orderId, 2)
            .then(response => {
              if (response.data.code === 200) {
                this.shopList = response.data.data
              } else {
                this.$message({
                  message: response.data.msg,
                  type: 'warning'
                })
                return
              }
              resolve()
            })
            .catch(error => {
              reject(error)
            })
        })
      } else if (e === 2) {
        this.replaceType = 1
        return new Promise((resolve, reject) => {
          findDeliverGoodsStock(this.orderId, 3)
            .then(response => {
              if (response.data.code === 200) {
                this.shopList = response.data.data
              } else {
                this.$message({
                  message: response.data.msg,
                  type: 'warning'
                })
                return
              }
              resolve()
            })
            .catch(error => {
              reject(error)
            })
        })
      } else {
        this.replaceType = 0
        return new Promise((resolve, reject) => {
          findDeliverGoodsStock(this.orderId, 4)
            .then(response => {
              if (response.data.code === 200) {
                this.shopList = response.data.data
              } else {
                this.$message({
                  message: response.data.msg,
                  type: 'warning'
                })
                return
              }
              resolve()
            })
            .catch(error => {
              reject(error)
            })
        })
      }
    },
    // 代寄代发开关
    replaceSwitck () {
      this.replace = !this.replace
    },
    // 抬头类型
    riseBtn (e) {
      this.riseType = e
    },
    // 选择导购
    guideBtn (e) {
      this.guideType = e
    },
    arrowDown (type) {
      this.isShowSelect = type
    },
    select (item, index, id) {
      // console.log(item)
      // console.log(index)
      // 导购员
      if (this.isShowSelect === 1) {
        this.memberDetail.userName = item
        this.isShowSelect = 0
        return
      }
      // 会员等级
      if (this.isShowSelect === 2) {
        this.levelTitle = item.value
        this.memberDetail.level = item.key
        this.isShowSelect = 0
        return
      }
      // 证件类型
      if (this.isShowSelect === 3) {
        this.credentialName = item.value
        this.memberDetail.credentialType = item.key
        this.isShowSelect = 0
        return
      }
      // 选择入会门店
      if (this.isShowSelect === 4) {
        this.memberDetail.storeName = item
        this.memberDetail.storeId = id
        this.isShowSelect = 0
        return
      }
      this.isShowSelect = 0
      this.unitModel = index
      this.unitName = item.value
    },
    // 新增会员关闭
    closeAssmember () {
      this.addMemberStatu = 0
    },
    addMember () {
      this.addMemberStatu = 1
      return new Promise((resolve, reject) => {
        // 查询门店导购
        sysStore()
          .then(response => {
            if (response.data.code === 200) {
              this.sysStoreList = response.data.data
              this.sysStoreList.forEach(item => {
                if (item.id === this.userDetail.storeId) {
                  this.memberDetail.storeName = item.storeName
                }
              })
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 新增收货地址
    addAddress () {
      this.editAddressDetail = {}
      this.addAdressStatu = 1
      this.addAdressType = 1
    },
    closeAddress () {
      this.addAdressStatu = 0
      this.addAdressType = 0
      this.editAddressDetail = {}
    },
    // 挂单
    hangingOrder () {
      if (this.hangingType === 0) {
        this.hangingStatu = 1
        this.hangingType = 1
        return new Promise((resolve, reject) => {
          // 查询挂单列表
          hangingList('')
            .then(response => {
              if (response.data.code === 200) {
                this.hangingList = response.data.data
              } else {
                this.$message({
                  message: response.data.msg,
                  type: 'warning'
                })
                return
              }
              resolve()
            })
            .catch(error => {
              reject(error)
            })
        })
      } else {
        this.$confirm('确定挂起该订单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.orderList = []
          sessionStorage.removeItem('orderList')
          this.closeMember()
          this.hangingStatu = 0
          this.hangingType = 0
          this.independentId = ''
          this.independentId2 = ''
          this.independent = '请选择'
          this.independent2 = '请选择'
          this.independentType = 0
          this.orderId = ''
          sessionStorage.removeItem('memberContent')
          sessionStorage.removeItem('independent')
          sessionStorage.removeItem('independent2')
          this.memberContent = ''
          this.goodsTotalNum = 0
          this.totalAmountPrice = 0
          this.totalAmountPrice2 = 0
          this.memberPrice = 0
          this.discountStatu = 0
          // this.sunNum()
          this.$message({
            type: 'success',
            message: '挂单成功!'
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消挂单'
          })
        })
      }
    },
    // 根据会员编号/手机号查询会员信息
    queryMember () {
      if (this.memberCode === '' && this.memnerPhone === '') {
        this.$message({
          message: '请输入查询内容',
          type: 'warning'
        })
        return
      }
      this.invoice = {
        name: '',
        dutyParagraph: '',
        email: '',
        headerType: this.riseType
      }
      this.cashPay = ''
      this.cashGivechange = ''
      // 押金
      this.deposit = ''
      // 组合支付
      this.combinationPay1 = ''
      this.combinationPay2 = ''
      this.inputFocusIndex = ''
      return new Promise((resolve, reject) => {
        memberDetail(this.memberCode, this.memnerPhone)
          .then(response => {
            if (response.data.code === 200) {
              if (response.data.data != null) {
                this.$message({
                  showClose: true,
                  message: '查询成功',
                  type: 'success'
                })
              } else {
                this.$message({
                  message: '查询失败',
                  type: 'warning'
                })
              }
              this.maskType = 0
              this.memberContent = response.data.data
              this.memberAddress(response.data.data.id)
              var memberContent = JSON.stringify(response.data.data)
              sessionStorage.setItem('memberContent', memberContent)
              this.sunNum()
              // sessionStorage.setItem('memberCode', this.memberCode)
              if (this.orderId === '') {
                return
              }
              var data = {
                id: this.orderId,
                memberId: response.data.data.id
              }
              updateOrders(data)
                .then(response => {
                  if (response.data.code === 200) {} else {
                    this.$message({
                      message: response.data.msg,
                      type: 'warning'
                    })
                    return
                  }
                  resolve()
                })
                .catch(error => {
                  reject(error)
                })
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 新增会员
    addMemberClick () {
      if (this.memberDetail.memberName === '') {
        this.$message({
          message: '请输入会员名称',
          type: 'warning'
        })
        return
      }
      if (this.memberDetail.userName === '请选择导购员') {
        this.$message({
          message: '请选择导购员',
          type: 'warning'
        })
        return
      }
      if (this.memberDetail.phone === '') {
        this.$message({
          message: '请输入手机号码',
          type: 'warning'
        })
        return
      }
      const a = /^1\d{10}$/
      if (!(a.test(this.memberDetail.phone))) {
        this.$message({
          message: '手机号格式不正确',
          type: 'warning'
        })
        return
      }
      if (this.memberDetail.level === '') {
        this.$message({
          message: '请选择会员等级',
          type: 'warning'
        })
        return
      }
      var data = this.memberDetail
      return new Promise((resolve, reject) => {
        addMember(data)
          .then(response => {
            if (response.data.code === 200) {
              this.$message({
                showClose: true,
                message: '新增会员成功',
                type: 'success',
                duration: 30000
              })
              this.addMemberStatu = 0
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 组合支付输入事件
    combinationInput (type) {
      // console.log(type)
      if (type === 1) {
        this.combinationPay2 = (this.totalAmountPrice2 - this.integralInput) - Number(this.combinationPay1)
      } else {
        this.combinationPay1 = (this.totalAmountPrice2 - this.integralInput) - Number(this.combinationPay2)
      }
    },
    // 键盘输入
    keyBoard (type, num) {
      // type 1-现金支付 2-押金支付 3-组合支付 4-整单折扣 discountTxt
      if (type === 1) {
        this.cashPay = this.cashPay + num
        if (Number(this.cashPay) < this.totalAmountPrice2 - this.integralInput) {
          this.$message({
            message: '现金不能低于支付金额',
            type: 'warning'
          })
        } else {
          this.cashGivechange = parseInt(Number(this.cashPay) - (this.totalAmountPrice2 - this.integralInput))
        }
      } else if (type === 2) {
        this.deposit = this.deposit + num
      } else if (type === 3) {
        // console.log('打印')
        if (this.inputFocusIndex === 3) {
          this.combinationPay1 = this.combinationPay1 + num
          this.combinationPay2 = (this.totalAmountPrice2 - this.integralInput) - Number(this.combinationPay1)
        } else {
          this.combinationPay2 = this.combinationPay2 + num
          this.combinationPay1 = this.totalAmountPrice2 - this.integralInput - Number(this.combinationPay2)
        }
      } else if (type === 4) {
        if (this.discountTxt === '折扣比例') {
          this.$message({
            type: 'info',
            message: '请选择折扣比例'
          })
          // this.discountRatio = this.discountRatio + num
        } else {
          this.discountPrice = this.discountPrice + num
        }
      }
    },
    // 删除输入
    deleteInput (type) {
      // type 1-现金支付 2-押金支付 3-组合支付
      console.log('删除')
      if (type === 1) {
        if (this.cashPay.length <= 0) {
          return
        }
        var str = this.cashPay
        this.cashPay = str.substr(0, str.length - 1)
      } else if (type === 2) {
        if (this.cashPay.deposit <= 0) {
          return
        }
        var str2 = this.deposit
        this.deposit = str2.substr(0, str2.length - 1)
      } else if (type === 3) {
        if (this.inputFocusIndex === 3) {
          if (this.combinationPay1 <= 0) {
            return
          }
          var str3 = this.combinationPay1
          this.combinationPay1 = str3.substr(0, str3.length - 1)
          this.combinationPay2 = (this.totalAmountPrice2 - this.integralInput) - Number(this.combinationPay1)
        } else {
          if (this.combinationPay2 <= 0) {
            return
          }
          var str4 = this.combinationPay2
          // console.log(str4)
          this.combinationPay2 = str4.substr(0, str4.length - 1)
          this.combinationPay1 = this.totalAmountPrice2 - this.integralInput - Number(this.combinationPay2)
          // this.combinationPay2 = Number(this.combinationPay2)
        }
      } else if (type === 4) {
        if (this.discountTxt === '折扣比例') {
          // if (this.discountRatio <= 0) {
          //   return
          // }
          // var str5 = this.discountRatio
          // this.discountRatio = str5.substr(0, str5.length - 1)
          this.$message({
            type: 'info',
            message: '请选择折扣比例'
          })
        } else {
          if (this.discountPrice <= 0) {
            return
          }
          var str6 = this.discountPrice
          this.discountPrice = str6.substr(0, str6.length - 1)
        }
      }
    },
    thisInput (type) {
      if (type === 1) {
        if (Number(this.cashPay) < this.totalAmountPrice2 - this.integralInput) {
          this.$message({
            message: '现金不能低于支付金额',
            type: 'warning'
          })
        } else {
          this.cashGivechange = parseInt(Number(this.cashPay) - (this.totalAmountPrice2 - this.integralInput))
        }
      }
    },
    inputFocus (type) {
      this.inputFocusIndex = type
    },
    combinationPay () {
      if (this.combinationPay1 === '' || this.combinationPay2 === '') {
        this.$message({
          message: '请输入支付金额',
          type: 'warning'
        })
        return
      }
      if ((Number(this.combinationPay1) + Number(this.combinationPay2)) > (parseInt(this.totalAmountPrice2) -
            parseInt(this.integralInput))) {
        this.$message({
          message: '支付金额大于应收金额',
          type: 'warning'
        })
        return
      }
      if ((Number(this.combinationPay1) + Number(this.combinationPay2)) < (parseInt(this.totalAmountPrice2) -
            parseInt(this.integralInput))) {
        this.$message({
          message: '支付金额小于应收金额',
          type: 'warning'
        })
        return
      }
      this.clickPay(4)
    },
    // 查询会员收货地址
    memberAddress (id) {
      return new Promise((resolve, reject) => {
        checkAddress(id)
          .then(response => {
            if (response.data.code === 200) {
              this.addressList = response.data.data
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 默认收货地址
    defaultClick (id) {
      var data = {
        memberId: this.memberContent.id,
        id: id,
        state: 1
      }
      return new Promise((resolve, reject) => {
        defaultAddress(data)
          .then(response => {
            if (response.data.code === 200) {
              this.memberAddress(this.memberContent.id)
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 编辑收货地址
    editAddress (index) {
      this.addAdressStatu = 1
      this.editAddressDetail = this.addressList[index]
    },
    // 编辑地址保存
    preservationAddress () {
      if (this.editAddressDetail.address === undefined || this.editAddressDetail.address === '') {
        this.$message({
          message: '请选择收货地址',
          type: 'warning'
        })
        return
      }
      if (this.editAddressDetail.addressDetail === undefined || this.editAddressDetail.addressDetail === '') {
        this.$message({
          message: '请输入详细收货地址',
          type: 'warning'
        })
        return
      }
      if (this.editAddressDetail.linkman === undefined || this.editAddressDetail.linkman === '') {
        this.$message({
          message: '请输入收件人',
          type: 'warning'
        })
        return
      }
      if (this.editAddressDetail.phone === undefined || this.editAddressDetail.phone === '') {
        this.$message({
          message: '请输入收件人联系方式',
          type: 'warning'
        })
        return
      }
      if (this.addAdressType === 1) {
        var data2 = {
          memberId: this.memberContent.id,
          address: this.editAddressDetail.address[0] + ' ' + this.editAddressDetail.address[1] + ' ' + this
            .editAddressDetail.address[2],
          addressDetail: this.editAddressDetail.addressDetail,
          linkman: this.editAddressDetail.linkman,
          phone: this.editAddressDetail.phone
        }
        return new Promise((resolve, reject) => {
          addAddress(data2)
            .then(response => {
              if (response.data.code === 200) {
                this.addAdressStatu = 0
                this.addAdressType = 0
                this.memberAddress(this.memberContent.id)
              } else {
                this.$message({
                  message: response.data.msg,
                  type: 'warning'
                })
                return
              }
              resolve()
            })
            .catch(error => {
              reject(error)
            })
        })
      } else {
        var address = ''
        if (typeof (this.editAddressDetail.address) === 'string') {
          address = this.editAddressDetail.address
        } else {
          address = this.editAddressDetail.address[0] + ' ' + this.editAddressDetail.address[1] + ' ' + this
            .editAddressDetail.address[2]
        }
        var data = {
          memberId: this.memberContent.id,
          address: address,
          addressDetail: this.editAddressDetail.addressDetail,
          linkman: this.editAddressDetail.linkman,
          phone: this.editAddressDetail.phone,
          id: this.editAddressDetail.id
        }
        return new Promise((resolve, reject) => {
          defaultAddress(data)
            .then(response => {
              if (response.data.code === 200) {
                this.addAdressStatu = 0
                this.memberAddress(this.memberContent.id)
              } else {
                this.$message({
                  message: response.data.msg,
                  type: 'warning'
                })
                return
              }
              resolve()
            })
            .catch(error => {
              reject(error)
            })
        })
      }
    },
    // 删除收货地址
    deleteAddress (id) {
      this.$confirm('确定删除该收货地址?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return new Promise((resolve, reject) => {
          deletememberAddress(id)
            .then(response => {
              if (response.data.code === 200) {
                this.memberAddress(this.memberContent.id)
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              } else {
                this.$message({
                  message: response.data.msg,
                  type: 'warning'
                })
                return
              }
              resolve()
            })
            .catch(error => {
              reject(error)
            })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 关闭会员
    closeMember () {
      this.memberContent = ''
      // sessionStorage.removeItem('memnerPhone')
      sessionStorage.removeItem('memberContent')
      this.replace = false
      this.integralInput = 0
      this.deliverType = 0
      this.deliverType = 0
      this.deliverGoodsShop = ''
      // 他店代发
      this.replaceType = 0
      this.stockIndex = -1
    },
    // 搜索商品
    search (type) {
      // 1-条码搜索 2-多项搜索
      if (this.scanningNum === '') {
        return
      }
      return new Promise((resolve, reject) => {
        getBySpecNo(this.scanningNum)
          .then(response => {
            if (response.data.code === 200) {
              this.hangingType = 1
              // if (response.data.data.stock === 0) {
              //   this.$message({
              //     message: '该商品暂无库存~',
              //     type: 'warning'
              //   })
              //   return
              // }
              // 新增
              if (this.orderId === '') {
                let orderGuideVOList = []
                if (this.independentType === 1) {
                  if (this.guideType === 1) {
                    orderGuideVOList = [{
                      guideId: this.independentId,
                      guideName: this.independent,
                      type: this.guideType,
                      ratio: '100'
                    }]
                  } else if (this.guideType === 2) {
                    orderGuideVOList = [{
                      guideId: this.independentId,
                      guideName: this.independent,
                      type: this.guideType,
                      ratio: '50'
                    },
                    {
                      guideId: this.independentId2,
                      guideName: this.independent2,
                      type: this.guideType,
                      ratio: '50'
                    }
                    ]
                  } else if (this.guideType === 3) {
                    orderGuideVOList = [{
                      guideId: this.independentId,
                      guideName: this.independent,
                      type: this.guideType,
                      ratio: '80'
                    },
                    {
                      guideId: this.independentId2,
                      guideName: this.independent2,
                      type: this.guideType,
                      ratio: '20'
                    }
                    ]
                  }
                }
                var data = {
                  orderDetailVOList: [{
                    goodsId: response.data.data.goodsId,
                    goodsNo: response.data.data.goodsNo,
                    specId: response.data.data.specId,
                    specNo: response.data.data.specNo,
                    number: 1
                  }],
                  memberId: this.memberContent.id,
                  orderGuideVOList: orderGuideVOList
                }
                response.data.data.number = 1
                var orderList = response.data.data
                this.orderList = []
                this.orderList.push(orderList)
                addOrder(data)
                  .then(response => {
                    if (response.data.code === 200) {
                      this.orderId = response.data.data
                      var orderList = JSON.stringify(this.orderList)
                      sessionStorage.setItem('orderList', orderList)
                      sessionStorage.setItem('orderId', this.orderId)
                      this.scanningNum = ''
                      this.sunNum()
                    } else {
                      this.$message({
                        message: response.data.msg,
                        type: 'warning'
                      })
                      return
                    }
                    resolve()
                  })
                  .catch(error => {
                    reject(error)
                  })
              } else {
                // 修改商品
                for (var i = 0; i < this.orderList.length; i++) {
                  if (this.orderList[i].specId === response.data.data.specId) {
                    this.orderList[i].number = this.orderList[i].number + 1
                    this.orderList[i].orderId = this.orderId
                    editOrderDetail(this.orderList[i])
                      .then(response => {
                        if (response.data.code === 200) {
                          var orderList = JSON.stringify(this.orderList)
                          sessionStorage.setItem('orderList', orderList)
                          sessionStorage.setItem('orderId', this.orderId)
                          this.scanningNum = ''
                          this.sunNum()
                        } else {
                          this.orderList[i].number = this.orderList[i].number - 1
                          this.sunNum()
                          this.$message({
                            message: response.data.msg,
                            type: 'warning'
                          })
                          return
                        }
                        resolve()
                      })
                      .catch(error => {
                        reject(error)
                      })
                    return
                  }
                }
                response.data.data.number = 1
                response.data.data.orderId = this.orderId
                var orderList1 = response.data.data
                orderList1.orderId = this.orderId
                this.orderList.push(orderList1)
                // 新增商品
                addOrderDetail(orderList1)
                  .then(response => {
                    if (response.data.code === 200) {
                      // this.orderList.push(response.data.data)
                      // this.orderId = response.data.data
                      var orderList = JSON.stringify(this.orderList)
                      sessionStorage.setItem('orderList', orderList)
                      sessionStorage.setItem('orderId', this.orderId)
                      this.scanningNum = ''
                      this.sunNum()
                    } else {
                      this.$message({
                        message: response.data.msg,
                        type: 'warning'
                      })
                      return
                    }
                    resolve()
                  })
                  .catch(error => {
                    reject(error)
                  })
              }
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 选择导购
    composeValue (item, id, type) {
      return {
        nickName: item,
        id: id,
        type: type
      }
    },
    // 确定导购 1独立销售
    confirmGuide () {
      if (this.guideType === 1) {
        if (this.independentId === '') {
          this.$message({
            message: '请选择导购员',
            type: 'warning'
          })
          this.independentType = 0
          return
        }
        this.maskType = 0
        this.independentType = 1
      } else {
        if (this.independentId === '' || this.independentId2 === '') {
          this.$message({
            message: '请选择导购员',
            type: 'warning'
          })
          this.independentType = 0
          return
        }
        if (this.independentId === this.independentId2) {
          this.$message({
            message: '导购员不能重复',
            type: 'warning'
          })
          this.independent2 = '请选择'
          this.independentType = 0
        } else {
          this.maskType = 0
          this.independentType = 1
        }
      }
      if (this.orderId === '') {
        return
      }
      let orderGuideVOList = []
      if (this.guideType === 1) {
        orderGuideVOList = [{
          guideId: this.independentId,
          guideName: this.independent,
          type: this.guideType,
          ratio: '100',
          orderId: this.orderId
        }]
      } else if (this.guideType === 2) {
        orderGuideVOList = [{
          guideId: this.independentId,
          guideName: this.independent,
          type: this.guideType,
          ratio: '50',
          orderId: this.orderId
        },
        {
          guideId: this.independentId2,
          guideName: this.independent2,
          type: this.guideType,
          ratio: '50',
          orderId: this.orderId
        }
        ]
      } else if (this.guideType === 3) {
        orderGuideVOList = [{
          guideId: this.independentId,
          guideName: this.independent,
          type: this.guideType,
          ratio: '80',
          orderId: this.orderId
        },
        {
          guideId: this.independentId2,
          guideName: this.independent2,
          type: this.guideType,
          ratio: '20',
          orderId: this.orderId
        }
        ]
      }
      // console.log(orderGuideVOList)
      return new Promise((resolve, reject) => {
        addorderGuide(orderGuideVOList)
          .then(response => {
            if (response.data.code === 200) {
              if (orderGuideVOList.length === 1) {
                sessionStorage.setItem('independent', this.independent)
              } else {
                sessionStorage.setItem('independent', this.independent)
                sessionStorage.setItem('independent2', this.independent2)
              }
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 清除导购员信息
    deleteGuide () {
      this.independent = '请选择'
      this.independent2 = '请选择'
    },
    // 关闭取单列表
    closeHanging () {
      this.hangingStatu = 0
      this.hangingType = 0
    },
    // 取单
    takeHanging (i) {
      this.orderId = this.hangingList[i].id
      this.shopList = []
      // var orderList = JSON.stringify(this.orderList)
      return new Promise((resolve, reject) => {
        findByOrderId(this.orderId)
          .then(response => {
            if (response.data.code === 200) {
              this.hangingStatu = 0
              this.orderList = response.data.data.orderDetailVOList
              this.memberContent = response.data.data.memberVO
              var orderList = JSON.stringify(this.orderList)
              sessionStorage.setItem('orderList', orderList)
              sessionStorage.setItem('orderId', this.orderId)
              var memberContent = JSON.stringify(response.data.data.memberVO)
              sessionStorage.setItem('memberContent', memberContent)
              this.sunNum()
              // independent level
              if (response.data.data.orderGuideVOList != null) {
                if (response.data.data.orderGuideVOList.length === 1) {
                  this.independent = response.data.data.orderGuideVOList[0].guideName
                  this.independentId = response.data.data.orderGuideVOList[0].guideId
                  this.independentType = 1
                  sessionStorage.setItem('independent', response.data.data.orderGuideVOList[0].guideName)
                } else {
                  this.independent = response.data.data.orderGuideVOList[0].guideName
                  this.independentId = response.data.data.orderGuideVOList[0].guideId
                  this.independentType = 1
                  this.independent2 = response.data.data.orderGuideVOList[1].guideName
                  this.independentId2 = response.data.data.orderGuideVOList[1].guideId
                  sessionStorage.setItem('independent', response.data.data.orderGuideVOList[0].guideName)
                  sessionStorage.setItem('independent2', response.data.data.orderGuideVOList[1].guideName)
                }
              }
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
        hangingList('')
          .then(response => {
            if (response.data.code === 200) {
              this.hangingList = response.data.data
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 修改商品数量
    editNum (type, index) {
      return new Promise((resolve, reject) => {
        // 1-减 2-加
        if (type === 1) {
          if (this.orderList[index].number - 1 <= 0) {
            if (this.orderList.length === 1) {
              this.$confirm('确定要删除此订单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.deletetChrt()
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除'
                })
              })
              return
            }
            this.deleteOrderList(index)
            return
          }
          this.orderList[index].orderId = this.orderId
          this.orderList[index].number = this.orderList[index].number - 1
        } else {
          // if (this.orderList[index].number === this.orderList[index].stock) {
          //   this.$message({
          //     type: 'info',
          //     message: '该商品剩余库存' + this.orderList[index].stock
          //   })
          //   return
          // }
          this.orderList[index].orderId = this.orderId
          this.orderList[index].number = this.orderList[index].number + 1
        }
        editOrderDetail(this.orderList[index])
          .then(response => {
            if (response.data.code === 200) {
              var orderList = JSON.stringify(this.orderList)
              sessionStorage.setItem('orderList', orderList)
              sessionStorage.setItem('orderId', this.orderId)
              this.sunNum()
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 输入框修改数量
    editNumBlur (num, index) {
      if (num > 0 && num < this.orderList[index].stock) {
        this.orderList[index].number = num
        this.orderList[index].orderId = this.orderId
        return new Promise((resolve, reject) => {
          editOrderDetail(this.orderList[index])
            .then(response => {
              if (response.data.code === 200) {
                var orderList = JSON.stringify(this.orderList)
                sessionStorage.setItem('orderList', orderList)
                sessionStorage.setItem('orderId', this.orderId)
                this.sunNum()
              } else {
                this.$message({
                  message: response.data.msg,
                  type: 'warning'
                })
                return
              }
              resolve()
            })
            .catch(error => {
              reject(error)
            })
        })
      } else {
        this.orderList[index].number = 1
        this.$message({
          message: '请输入正确数量',
          type: 'warning'
        })
      }
    },
    // 删除商品
    deleteOrderList (index) {
      if (this.orderList.length === 1) {
        this.$confirm('确定要删除此订单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deletetChrt()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
        return
      }
      return new Promise((resolve, reject) => {
        delelteOrderDetail(this.orderId, this.orderList[index].specId)
          .then(response => {
            if (response.data.code === 200) {
              var a = this.orderList.splice(index, 1)
              console.log(a)
              var orderList = JSON.stringify(this.orderList)
              sessionStorage.setItem('orderList', orderList)
              sessionStorage.setItem('orderId', this.orderId)
              this.replace = false
              this.deliverType = 0
              this.deliverType = 0
              this.deliverGoodsShop = ''
              // 他店代发
              this.replaceType = 0
              this.stockIndex = -1
              this.sunNum()
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 删除订单
    deletetChrt () {
      return new Promise((resolve, reject) => {
        delelteOrder(this.orderId)
          .then(response => {
            if (response.data.code === 200) {
              this.orderId = ''
              this.orderList = ''
              this.hangingType = 0
              sessionStorage.clear()
              // this.sunNum()
              this.goodsTotalNum = 0
              this.totalAmountPrice = 0
              this.totalAmountPrice2 = 0
              this.memberPrice = 0
              this.independent = '请选择'
              this.independentId = ''
              // 导购2
              this.independent2 = '请选择'
              this.independentId2 = ''
              this.independentType = 0
              this.memberContent = ''
              this.integralInput = ''
              this.closeDiscountStatu()
              // 查询挂单列表
              hangingList('')
                .then(response => {
                  if (response.data.code === 200) {
                    this.hangingList = response.data.data
                  } else {
                    this.$message({
                      message: response.data.msg,
                      type: 'warning'
                    })
                    return
                  }
                  resolve()
                })
                .catch(error => {
                  reject(error)
                })
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 删除订单
    deleteHanging (id) {
      this.orderId = id
      this.$confirm('确定要删除此订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deletetChrt()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 计算商品总数
    sunNum () {
      console.log(this.orderList, '-----')
      this.goodsTotalNum = 0
      this.totalAmountPrice = 0
      this.totalAmountPrice2 = 0
      this.memberPrice = 0
      this.localPrice = 0
      this.joinPromoPrice = 0
      this.orderList.forEach(item => {
        this.goodsTotalNum = Number(this.goodsTotalNum + item.number)
        this.localPrice = this.localPrice + (item.number * item.localPrice)
        if (item.goodsState === 4) {
          this.totalAmountPrice = this.totalAmountPrice + (item.number * item.localDiscountPrice)
        } else {
          this.totalAmountPrice = this.totalAmountPrice + (item.number * item.localPrice)
        }
        this.totalAmountPrice2 = this.totalAmountPrice
      })
      var level = this.memberContent.level
      this.orderList.forEach(item => {
        if (item.isJoinPromo == 1) {
          if (item.goodsState === 4) {
            this.joinPromoPrice += parseInt(item.number * item.localDiscountPrice)
          } else {
            this.joinPromoPrice += parseInt(item.number * item.localPrice)
          }
        }
      })
      if (level === 2) {
        this.memberPrice = this.joinPromoPrice - parseInt(this.joinPromoPrice * 0.95)
        // this.memberPrice = parseInt(this.memberPrice)
      } else if (level === 3) {
        this.memberPrice = this.joinPromoPrice - parseInt(this.joinPromoPrice * 0.9)
        // this.memberPrice = parseInt(this.memberPrice)
      } else if (level === 4) {
        this.memberPrice = this.joinPromoPrice - parseInt(this.joinPromoPrice * 0.88)
        // this.memberPrice = parseInt(this.memberPrice)
      } else if (level === 5) {
        this.memberPrice = this.joinPromoPrice - parseInt(this.joinPromoPrice * 0.8)
        // this.memberPrice = parseInt(this.memberPrice)
      }
      this.totalAmountPrice2 = parseInt(this.totalAmountPrice2) - parseInt(this.memberPrice)
      if (this.discountStatu === 1) {
        this.discountClick()
      }
    },
    // 添加备注
    editMsg () {
      if (this.textarea === '') {
        this.$message({
          type: 'info',
          message: '请输入备注信息'
        })
        return
      }
      var data = {
        id: this.orderId,
        remark: this.textarea
      }
      return new Promise((resolve, reject) => {
        updateOrders(data)
          .then(response => {
            if (response.data.code === 200) {
              this.payClick(0)
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 根据手机号搜索挂单列表
    hangingSearch () {
      // this.searchPhone
      return new Promise((resolve, reject) => {
        // 查询挂单列表
        hangingList(this.searchPhone)
          .then(response => {
            if (response.data.code === 200) {
              this.hangingList = response.data.data
              this.searchPhone = ''
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 积分抵扣使用积分
    integralConfirm () {
      // console.log('进入')
      // 可用积分
      if (this.integralInput === '') {
        this.$message({
          type: 'info',
          message: '请输入使用积分额'
        })
        this.integralInput = ''
        return
      }
      if (Number(this.integralInput) !== Number(this.integralPrice) && Number(this.integralInput) !== 0) {
        this.$message({
          type: 'info',
          message: '该会员可用' + this.integralPrice + '积分'
        })
        this.integralInput = ''
        return
      }
      this.integralInput = Number(this.integralInput)
      // this.totalAmountPrice2 = parseInt(this.totalAmountPrice2 - this.integralInput)
      // this.integralInput = ''
      this.maskType = 0
      this.footerIndex = -1
      this.memberCode = ''
      this.memnerPhone = ''
    },
    // 添加发票信息
    invoiceConfirm () {
      // 1-个人 2-企业
      this.invoice.invoiceType = this.riseType
      if (this.invoice.name === '') {
        this.$message({
          type: 'info',
          message: '请输入抬头名称'
        })
        return
      }
      if (this.invoice.email === '') {
        this.$message({
          type: 'info',
          message: '请输入电子邮箱'
        })
        return
      }
      if (this.invoice.phone === '') {
        this.$message({
          type: 'info',
          message: '请输入手机号码'
        })
        return
      }
      const a = /^1\d{10}$/
      if (!a.test(this.invoice.phone)) {
        this.$message({
          type: 'info',
          message: '手机号格式不正确'
        })
        return
      }
      if (this.riseType === 2) {
        if (this.invoice.dutyParagraph === '') {
          this.$message({
            type: 'info',
            message: '请输入单位税号'
          })
          return
        }
      }
      this.payClick(0)
    },
    // 代寄代发
    stockSee (index, id) {
      // console.log(id)
      if (index === this.stockIndex) {
        this.stockIndex = -1
        return
      }
      this.stockIndex = index
      this.deliverGoodsShop = id
    },
    clickStock (id) {
      return new Promise((resolve, reject) => {
        findStoreGoodsStock(id, this.orderId)
          .then(response => {
            if (response.data.code === 200) {
              this.storeGoodsStock = response.data.data
              this.storeGoodsStatu = 1
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    closeStoreGoods () {
      this.storeGoodsStatu = 0
    },
    sendGoods () {
      if (!this.replace) {
        this.payClick(0)
        return
      }
      // console.log('打印--')
      // console.log(this.stockIndex)
      // if (this.replaceType === 1) {
      if (this.stockIndex === -1) {
        this.$message({
          type: 'info',
          message: '请选择代发店铺'
        })
        return
      }
      // }
      this.payClick(0)
    },
    // 整单折扣
    discountClick () {
      // console.log(this.totalAmountPrice)
      if (this.discountTxt === '折扣比例') {
        if (this.discountRatio === '') {
          this.$message({
            type: 'info',
            message: '请输折扣比例'
          })
          return
        }
        var num = parseInt(this.totalAmountPrice - (this.totalAmountPrice * Number(1 - Number(this.discountRatio2))) -
            Number(this.integralInput))
        if (num < 0) {
          this.$message({
            type: 'info',
            message: '请选择正确折扣比例'
          })
          return
        }
        this.totalAmountPrice2 = this.totalAmountPrice
        this.totalAmountPrice2 = parseInt(this.totalAmountPrice2 - (this.totalAmountPrice2 * Number(1 - Number(this
          .discountRatio2))))
        this.totalAmountPrice3 = this.discountRatio2
        // console.log(this.totalAmountPrice2)
        // console.log(num)
      }
      if (this.discountTxt === '修改总价') {
        if (this.discountPrice === '') {
          this.$message({
            type: 'info',
            message: '请输选折扣后总价'
          })
          return
        }
        if (this.discountPrice > this.totalAmountPrice2) {
          this.$message({
            type: 'info',
            message: '折扣价格大于原订单金额'
          })
          return
        }
        var num2 = Number(this.discountPrice) - Number(this.integralInput)
        if (num2 < 0) {
          this.$message({
            type: 'info',
            message: '请输入正确折扣价格'
          })
          return
        }
        // this.totalAmountPrice2 = this.totalAmountPrice
        this.totalAmountPrice2 = this.discountPrice
        this.totalAmountPrice3 = (this.discountPrice / this.totalAmountPrice).toFixed(4)
        // console.log(this.totalAmountPrice3)
        // console.log(this.totalAmountPrice)
      }
      this.discountStatu = 1
      this.payClick(0)
    },
    // 订单支付
    clickPay (type) {
      // console.log(this.deliverGoodsShop)
      if (this.replace) {
        if (this.stockIndex === -1) {
          this.$message({
            type: 'info',
            message: '请选择代发店铺'
          })
          return
        }
      }
      if (type === 3) {
        if (Number(this.cashPay) < this.totalAmountPrice2 - this.integralInput) {
          this.$message({
            message: '现金不能低于支付金额',
            type: 'warning'
          })
          this.cashPay = ''
          return
        }
      }
      if (this.independentType !== 1) {
        this.$message({
          type: 'info',
          message: '请选择导购'
        })
        return
      }
      // 2-pos支付
      var data = {}
      // 订单支付信息 2-pos 3-现金 5-押金 4-组合支付
      var orderPayVO = []
      if (type === 2) {
        orderPayVO = [{
          payType: type,
          payPrice: this.totalAmountPrice2 - this.integralInput,
          orderId: this.orderId
        }]
      }
      if (type === 3) {
        orderPayVO = [{
          payType: type,
          payPrice: this.totalAmountPrice2 - this.integralInput,
          orderId: this.orderId,
          giveChangePrice: this.cashGivechange
        }]
      }
      if (type === 5) {
        orderPayVO = [{
          payType: type,
          payPrice: this.deposit,
          orderId: this.orderId
        }]
      }
      if (type === 4) {
        var type1 = ''
        var type2 = ''
        if (this.combination1 === '微信支付') {
          type1 = 1
        } else if (this.combination1 === 'POS支付') {
          type1 = 2
        } else if (this.combination1 === '现金支付') {
          type1 = 3
        } else if (this.combination1 === '支付宝支付') {
          type1 = 6
        }
        if (this.combination2 === '微信支付') {
          type2 = 1
        } else if (this.combination2 === 'POS支付') {
          type2 = 2
        } else if (this.combination2 === '现金支付') {
          type2 = 3
        } else if (this.combination2 === '支付宝支付') {
          type2 = 6
        }
        orderPayVO = [{
          payType: type1,
          payPrice: this.combinationPay1,
          orderId: this.orderId
        },
        {
          payType: type2,
          payPrice: this.combinationPay2,
          orderId: this.orderId
        }
        ]
      }
      data.payType = type
      // 代寄代发
      if (this.replace) {
        this.addressList.forEach(item => {
          if (item.state === 1) {
            data.addressDetail = item.addressDetail
            data.consignee = item.linkman
            data.receivingAddress = item.address
            data.phone = item.phone
          }
        })
        if (data.addressDetail === undefined) {
          this.$message({
            type: 'info',
            message: '请选择收货地址'
          })
          return
        }
        data.deliverGoodsMode = this.deliverType + 1
        data.deliverGoodsShop = this.deliverGoodsShop
        data.isDeliverGoods = true
      } else {
        data.isDeliverGoods = false
      }
      data.id = this.orderId
      // 是否开发票
      if (this.invoice.name === '') {
        data.isInvoice = 0
      } else {
        data.isInvoice = 1
        data.orderInvoiceVO = this.invoice
        data.orderInvoiceVO.headerType = this.riseType
      }
      // 会员折扣 discountStatu 1-整单折扣
      if (this.discountStatu === 1) {
        data.orderDiscount = this.totalAmountPrice3
      } else {
        data.memberDiscount = this.memberPrice
      }
      // console.log('打印--')
      // console.log(this.memberContent)
      if (this.memberContent != null && this.memberContent !== '' && this.memberContent !== undefined) {
        data.memberId = this.memberContent.id
        // data.phone = this.memberContent.phone
        if (!this.replace) {
          data.phone = this.memberContent.phone
        }
      }
      data.orderPayVO = orderPayVO

      data.pointDiscount = this.integralInput
      data.totalPrice = this.totalAmountPrice2 - this.integralInput
      data.originalPrice = this.localPrice
      data.discountPrice = this.totalAmountPrice2 - this.integralInput
      // if (type === 2)
      // console.log(data)
      return new Promise((resolve, reject) => {
        // 查询挂单列表
        orderPay(data)
          .then(response => {
            if (response.data.code === 200) {
              this.orderList = []
              sessionStorage.removeItem('orderList')
              this.closeMember()
              this.hangingStatu = 0
              this.hangingType = 0
              this.independentId = ''
              this.independentId2 = ''
              this.independent = '请选择'
              this.independent2 = '请选择'
              this.independentType = 0
              this.orderId = ''
              sessionStorage.removeItem('memberContent')
              sessionStorage.removeItem('independent')
              sessionStorage.removeItem('independent2')
              this.memberContent = ''
              this.memberPrice = 0
              this.goodsTotalNum = 0
              this.totalAmountPrice = 0
              this.totalAmountPrice2 = 0
              this.integralInput = ''
              this.discountStatu = 0
              this.replace = false
              this.deliverType = 0
              this.shopList = []
              // this.sunNum()
              this.payClick(0)
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 展开购买记录
    recordClick () {
      // recordHeight: { height: '30rem', overflow: 'hidden' },
      if (this.recordStatu === 0) {
        this.recordStatu = 1
        this.recordHeight = {
          height: 'auto'
        }
      } else {
        this.recordStatu = 0
        this.recordHeight = {
          height: '30rem',
          overflow: 'hidden'
        }
      }
    },
    // 取消整单折扣
    closeDiscountStatu () {
      this.discountStatu = 0
      this.sunNum()
    }
    // 扫码
    // scanCode () {
    //   // console.log('浏览器信息')
    //   this.$router.push('/scan')
    // }
  }
}
</script>

<style scoped lang="less">
  // 日历

  /deep/.el-input__prefix {
    display: none;
  }

  /deep/.el-picker-panel {
    width: 200rem;
  }

  .meeting-date {
    width: 200rem !important;
  }

  p {
    padding: 0;
    margin: 0;
    text-align: left;
  }

  .box {
    // width: 100vh;
    height: 100vh;
    position: relative;
    padding: 0 0 150rem 0;
    overflow-y: auto;
    margin: 0;
    background-color: #F7F8FA;
  }

  input {
    border: none;
    outline: none;
    font-size: 14rem;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #999999;
    font-size: 16rem;
  }

  .el-icon-arrow-down {
    font-size: 16rem;
  }

  .dropdown-list {
    font-size: 16rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
    padding-left: 10%;
    width: 90%;
    text-align: left;
    line-height: 20rem;
  }

  // 指定门店的商品库存
  .stock {
    width: 540rem;
    background: #fff;
    border-radius: 10rem;
    overflow: hidden;
    position: fixed;
    top: 20%;
    left: 50%;
    margin-left: -270rem;
    z-index: 1060;
    padding-bottom: 40rem;

    .header {
      height: 44rem;
      background: #F3F3F3;
      display: flex;
      align-items: center;
      margin-bottom: 40rem;

      p {
        font-size: 16rem;
        color: #333333;
        margin-left: 243rem;
        flex: 1;
      }

      img {
        width: 15rem;
        height: 15rem;
        margin-right: 18rem;
      }
    }

    .detail {
      width: 500rem;
      margin: 20rem auto;
      border: 1rem solid #e8e8e8;

      .detail-top {
        width: 100%;
        height: 40rem;
        background: #f0f2f6;
        display: flex;
        align-items: center;

        .goods {
          flex: 1;
          text-align: center;
          height: 40rem;
          line-height: 40rem;
          border-right: 1rem solid #e8e8e8;
        }

        .detail-stock {
          width: 100rem;
          height: 40rem;
          line-height: 40rem;
          text-align: center;
        }
      }

      .stock-box {
        max-height: 500rem;
        overflow-y: auto;

        .detail-goods {
          width: 100%;
          display: flex;
          align-items: center;
          border-top: 1rem solid #e8e8e8;

          .detail-content {
            flex: 1;
            border-right: 1rem solid #e8e8e8;
            height: 100%;
            padding: 10rem 0;
          }

          .detail-goods-stock {
            width: 100rem;
            line-height: 40rem;
            text-align: center;
          }
        }
      }
    }
  }

  // 订单-查询
  .query {
    width: 100%;
    height: 98rem;
    background: #fff;
    position: fixed;
    top: 56rem;
    z-index: 999;

    .query-category {
      margin: 15rem 30rem 12rem 30rem;
      display: flex;

      .query-category-title {
        font-size: 14rem;
        color: #00281C;
        flex: 1;
        font-weight: 500;
      }

      .query-category-btn {
        width: 72rem;
        height: 24rem;
        border-radius: 3rem;
        text-align: center;
        line-height: 24rem;
        background: #00281C;
        font-size: 12rem;
        color: #fff;
        margin-right: 8rem;
        cursor: pointer;
      }

      .query-category-clear {
        width: 84rem;
        height: 24rem;
        border-radius: 3rem;
        text-align: center;
        line-height: 24rem;
        background: #BE0000;
        font-size: 12rem;
        color: #fff;
        cursor: pointer;
      }
    }

    .query-search {
      display: flex;
      margin: 0 30rem;
      align-items: center;

      .scanning {
        width: 684rem;
        height: 36rem;
        border-radius: 3rem;
        display: flex;
        align-items: center;
        border: 1rem solid #E6E6E6;

        .scanning-icon {
          width: 14rem;
          height: 14rem;
          margin: 0 10rem;
        }

        input {
          flex: 1;
        }
      }

      .accurate {
        width: 590rem;
        height: 36rem;
        border-radius: 3rem;
        display: flex;
        align-items: center;
        border: 1rem solid #E6E6E6;
        margin-left: 5rem;

        .icon {
          width: 14rem;
          height: 14rem;
          margin: 0 8rem 0 10rem;
        }

        .line {
          width: 1rem;
          height: 18rem;
          margin: 0 8rem;
          background: #D7DBE0;
        }
      }

      .search {
        margin-left: -8rem;
        width: 108rem;
        height: 36rem;
        border-radius: 2rem;
        background: #00281C;
        text-align: center;
        line-height: 36rem;
        font-size: 13rem;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  // 会员信息
  .member {
    width: 100%;
    margin-top: 154rem;
    background-color: #fff;
    border-top: 6rem solid #F7F8FA;
    padding-top: 12rem;
    text-align: left;
    padding-bottom: 16rem;

    .member-cashier {
      border-radius: 3rem;
      background: #EBEBEB;
      margin-left: 30rem;
      font-size: 12rem;
      color: #00281C;
      text-align: center;
      line-height: 18rem;
      padding: 0 6rem;
      display: inline-block;
      height: 18rem;
      position: relative;

      img {
        width: 12rem;
        height: 12rem;
        position: absolute;
        right: -6rem;
        top: -6rem;
      }
    }

    .member-detail {
      width: 1380rem;
      background: #F0F2F5;
      border-radius: 5rem;
      margin: 16rem auto 0 auto;
      position: relative;

      .close {
        width: 12rem;
        height: 12rem;
        position: absolute;
        right: -6rem;
        top: -6rem;
      }

      .user {
        display: flex;
        align-items: center;
        margin: 0 17rem 0 11rem;
        border-bottom: 1rem solid #E6E6E6;
        padding-bottom: 14rem;
        padding-top: 8rem;

        .user-photo {
          width: 50rem;
          height: 50rem;
          background: #eee;
          border-radius: 50%;
        }

        .user-detail {
          margin-left: 8rem;
          width: 206rem;
          margin-right: 10rem;

          .user-detail-information {
            display: flex;
            align-items: center;

            .name {
              font-size: 14rem;
              color: #333333;
              line-height: 20rem;
            }

            .phone {
              font-size: 12rem;
              color: #00281C;
              margin-left: 13rem;
            }

            .integral {
              font-family: 'Anton';
              height: 18rem;
              line-height: 18rem;
              padding: 0 9rem;
              display: inline-block;
              border-radius: 1rem;
              background: #00281C;
              font-size: 11rem;
              color: #fff;
            }

            .grade {
              width: 48rem;
              height: 18rem;
              margin-left: 3rem;
            }

            .icon {
              width: 14rem;
              height: 14rem;
              margin-left: 6rem;
            }
          }
        }

        .user-line {
          width: 1rem;
          height: 46rem;
          background: #E6E6E6;
          margin-right: 20rem;
        }

        .user-list {
          flex: 1;
          text-align: center;

          .user-list-name {
            font-size: 18rem;
            color: #00281C;
            line-height: 25rem;
            font-weight: 600;
            text-align: center;
          }

          .user-list-txt {
            font-size: 12rem;
            color: #999999;
            margin-top: 3rem;
            line-height: 17rem;
            text-align: center;
          }
        }
      }

      .record {
        display: flex;
        margin: 10rem 22rem 0 13rem;
        padding-bottom: 10rem;

        .record-txt {
          font-size: 12rem;
          color: #999999;
          flex: 1;
        }
      }
    }
  }

  // 商品信息
  .order {
    margin: 5rem auto 0 auto;
    display: flex;
    width: 1382rem;
    flex-wrap: wrap;

    .order-list {
      width: 689rem;
      height: 115rem;
      background: #fff;
      border-radius: 1rem;
      margin: 2rem 1rem 0 1rem;
      display: flex;
      align-items: center;

      .photo {
        width: 91rem;
        height: 91rem;
        background: #F2F2F2;
        margin-left: 12rem;
      }

      .detail {
        margin-left: 12rem;
        flex: 1;

        .detail-list {
          display: flex;
          align-items: center;
          margin-right: 15rem;

          .name {
            font-size: 15rem;
            color: #333333;
            flex: 1;
            line-height: 21rem;
          }

          .delete {
            width: 12rem;
            height: 12rem;
          }

          .title {
            font-size: 13rem;
            color: #999999;
          }

          .code {
            margin-left: 12rem;
            display: inline-block;
            height: 23rem;
            line-height: 23rem;
            padding: 0 6rem;
            background: #F7F8FA;
            border-radius: 2rem;
            font-size: 13rem;
            color: #999999;
          }

          .txt {
            font-size: 12rem;
            color: #333333;
          }

          .record-icon {
            width: 17rem;
            height: 17rem;
          }

          input {
            width: 26rem;
            text-align: center;
            font-size: 13rem;
            color: #00281C;
            font-family: 'Anton';
            margin: 0 6rem;
          }
        }
      }
    }
  }

  // 底部
  .footer {
    width: 100%;
    height: 138rem;
    box-shadow: 0rem -3rem 6rem 0rem rgba(0, 0, 0, 0.06);
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;

    .footer-detail {
      margin: 0 30rem;
      height: 60rem;
      display: flex;
      align-items: center;
      color: #333333;

      .footer-detail-name {
        font-size: 13rem;
        line-height: 22rem;
      }

      .footer-detail-num {
        font-size: 16rem;
      }

      .footer-detail-line {
        width: 1rem;
        height: 19rem;
        background: #D7DBE0;
        margin: 0 18rem;
      }

      .footer-detail-txt {
        font-size: 14rem;
        line-height: 20rem;
      }

      .footer-detail-sum {
        font-size: 14rem;
        color: #BE0000;
        line-height: 20rem;
        margin: 0 13rem 0 6rem;
      }

      .footer-detail-symbol {
        color: #BE0000;
        font-size: 13rem;
        margin-left: 4rem;
        font-weight: 600;
      }

      .footer-detail-price {
        font-size: 20rem;
        color: #BE0000;
        font-weight: 600;
      }
    }

    .footer-nav {
      display: flex;
      align-items: center;
      border-top: 1rem solid #D7DBE0;
      height: 76rem;

      .footer-nav-list {
        width: 71rem;
        height: 28rem;
        border-radius: 14rem;
        background: #F0F2F5;
        text-align: center;
        line-height: 28rem;
        font-size: 13rem;
        color: #00281C;
        margin-right: 24rem;
        cursor: pointer;
      }

      .footerFirst {
        margin-left: 30rem;
      }

      .activite {
        background: #00281C;
        color: #fff;
        transition: all 0.3s ease;
      }

      .take-order {
        width: 126rem;
        height: 40rem;
        border: 1rem solid #D7DBE0;
        border-radius: 3rem;
        text-align: center;
        line-height: 40rem;
        background: #F0F2F5;
        font-size: 16rem;
        color: #333333;
        cursor: pointer;
      }

      .footer-nav-line {
        width: 2rem;
        height: 40rem;
        background: #D7DBE0;
        margin: 0 40rem;
      }

      .footer-nav-pay {
        width: 105rem;
        height: 42rem;
        margin-right: 7rem;
        border-radius: 3rem;
        background: #00281C;
        text-align: center;
        line-height: 42rem;
        color: #fff;
        font-size: 16rem;
        cursor: pointer;
      }

      .footer-nav-pos {
        background: #296B57;
      }

      .footer-nav-last {
        margin-right: 30rem;
      }
    }
  }

  // 弹框
  .layer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #000;
    opacity: 0.6;
  }

  .masking {
    width: 752rem;
    height: 540rem;
    background: #fff;
    border-radius: 10rem;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -270rem;
    margin-left: -376rem;
    z-index: 1001;

    .masking-header {
      padding-top: 26rem;
      margin: 0 30rem;
      display: flex;

      p {
        font-size: 20rem;
        color: #00281C;
        line-height: 28rem;
        font-weight: 500;
        flex: 1;
      }

      img {
        width: 20rem;
        height: 20rem;
        margin-top: 10rem;
      }
    }

    .masking-title {
      font-size: 20rem;
      color: #333333;
      text-align: center;
      line-height: 28rem;
      margin-top: 22rem;
    }

    .masking-wait {
      font-size: 14rem;
      color: #969696;
      line-height: 20rem;
      text-align: center;
      margin-top: 8rem;
    }

    // pos支付
    .masking-pos {
      width: 353rem;
      height: 166rem;
      margin: 38rem auto 87rem auto;
      border-radius: 8rem;
      background: #F1F1F1;

      .title {
        font-size: 20rem;
        color: #666666;
        padding-top: 40rem;
        text-align: center;
      }

      .price {
        color: #BE0000;
        font-size: 50rem;
        text-align: center;
        line-height: 70rem;

        span {
          font-size: 14rem;
        }
      }
    }

    .masking-pay {
      width: 200rem;
      height: 50rem;
      border-radius: 2rem;
      background: #00281C;
      text-align: center;
      line-height: 50rem;
      font-size: 16rem;
      color: #fff;
      margin: 0 auto;
    }

    // 现金支付
    .masking-cash {
      margin: 50rem 0;
      display: flex;
      justify-content: center;

      .masking-cash-detail {
        .list {
          width: 289rem;
          height: 55rem;
          border-radius: 4rem;
          background: #F1F1F1;
          display: flex;
          align-items: center;
          margin-bottom: 13rem;
          border: 1rem solid #F1F1F1;

          .title {
            font-size: 16rem;
            color: #999999;
            margin-left: 17rem;
            margin-right: 28rem;
          }

          .price {
            flex: 1;
            font-size: 20rem;
            color: #BE0000;
          }

          .company {
            font-size: 20rem;
            margin-right: 20rem;
          }
        }
      }

      .masking-cash-keyboard {
        margin-left: 44rem;

        .list {
          display: flex;
          align-items: center;

          .list-detail {
            width: 56rem;
            height: 48rem;
            border-radius: 2rem;
            background: #F1F1F1;
            text-align: center;
            line-height: 48rem;
            font-size: 24rem;
            color: #333333;
            margin-left: 16rem;

            img {
              width: 18.4rem;
              height: 13.4rem;
              display: block;
              margin: 18rem auto;
            }
          }
        }
      }
    }
  }

  .discount {
    width: 432rem;
    height: 540rem;
    border-radius: 8rem;
    background: #fff;
    position: fixed;
    z-index: 1001;
    top: 50%;
    left: 50%;
    margin-top: -270rem;
    margin-left: -216rem;
    overflow: hidden;

    .discount-header {
      width: 100%;
      height: 44rem;
      background: #F3F3F3;
      display: flex;
      align-items: center;

      p {
        font-size: 16rem;
        color: #333333;
        margin-left: 184rem;
        flex: 1;
      }

      img {
        width: 16rem;
        height: 16rem;
        margin-right: 16rem;
      }
    }

    .discount-top {
      display: flex;
      align-items: center;
      margin: 70rem 0 32rem 0;
      justify-content: center;

      input {
        width: 107rem;
        height: 36rem;
        border-radius: 3rem;
        border: 1rem solid #E6E6E6;
        text-align: center;
        outline: none;
        font-size: 14rem;
        margin: 0 9rem;
      }
    }

    .masking-cash-keyboard {
      margin-left: 100rem;

      .list {
        display: flex;
        align-items: center;

        .list-detail {
          width: 56rem;
          height: 48rem;
          border-radius: 2rem;
          background: #F1F1F1;
          text-align: center;
          line-height: 48rem;
          font-size: 24rem;
          color: #333333;
          margin-left: 16rem;

          img {
            width: 18.4rem;
            height: 13.4rem;
            display: block;
            margin: 18rem auto;
          }
        }
      }
    }

    .btn {
      width: 200rem;
      height: 50rem;
      border-radius: 3rem;
      background: #00281C;
      text-align: center;
      line-height: 50rem;
      margin: 11rem auto 0 auto;
      font-size: 16rem;
      color: #fff;
    }
  }

  // 代寄代发
  .deduction {
    width: 752rem;
    padding-bottom: 40rem;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 10rem;
    top: 20%;
    left: 50%;
    margin-left: -376rem;
    overflow: hidden;

    .header {
      width: 100%;
      height: 44rem;
      background: #F3F3F3;
      display: flex;
      align-items: center;
      margin-bottom: 12rem;

      p {
        font-size: 16rem;
        color: #333333;
        margin-left: 344rem;
        flex: 1;
      }

      img {
        width: 16rem;
        height: 16rem;
        margin-right: 16rem;
      }
    }

    .list {
      display: flex;
      align-items: center;
      margin: 29rem 0 0 70rem;

      .title {
        width: 120rem;
        color: #333333;
        font-size: 14rem;
      }

      .switch {
        width: 44rem;
        height: 20rem;
        border-radius: 12rem;
        background: #00281C;

        .switch-line {
          width: 17rem;
          height: 17rem;
          background: #fff;
          border-radius: 50%;
          margin-top: 1rem;
          transition-duration: 0.5s;
        }
      }

      .add {
        width: 124rem;
        height: 36rem;
        border: 1rem solid #D7DBE0;
        border-radius: 3rem;
        text-align: center;
        line-height: 36rem;
        font-size: 14rem;
        color: #333333;
      }

      .freight {
        width: 228rem;
        height: 36rem;
        border-radius: 3rem;
        border: 1rem solid #E6E6E6;
        padding-left: 12rem;
        font-size: 14rem;
      }

      .activite {
        background: #00281C;
        border: 1rem solid #00281C;
        color: #fff !important;
        transition: all 0.3s ease;
      }

      .deliver {
        width: 78rem;
        height: 34rem;
        border-radius: 3rem;
        border: 1rem solid #D7DBE0;
        text-align: center;
        line-height: 34rem;
        color: #333333;
        font-size: 12rem;
        margin-right: 12rem;
      }

      .address {
        width: 469rem;
        height: 58rem;
        border: 1rem solid #D7DBE0;
        border-radius: 2rem;
        display: flex;
        align-items: center;

        .address-icon {
          width: 17rem;
          height: 17rem;
          background: #eee;
          border-radius: 50%;
          margin-left: 8rem;
        }

        .detail {
          margin-left: 15rem;
          flex: 1;

          p {
            font-size: 14rem;
            color: #333333;
            line-height: 20rem;
          }
        }

        .delete {
          width: 24rem;
          height: 24rem;
          background: #F1F1F1;
          border-radius: 50%;

          img {
            width: 11.5rem;
            height: 11.5rem;
            display: block;
            margin: 6rem;
          }
        }

        .edit {
          width: 12rem;
          height: 12rem;
          // background: #eee;
          margin: 0 16rem 0 14rem;
        }
      }

      .shop {
        width: 320rem;
        // height: 145rem;
        border-radius: 2rem;
        border: 1rem solid #F4F4F4;

        .list-top {
          height: 35rem;
          border-bottom: 1rem solid #E6E6E6;
          display: flex;
          align-items: center;
          background: #F4F4F4;

          .title {
            width: 216rem;
            text-align: center;
            font-size: 14rem;
            color: #000000;
          }

          .line {
            height: 35rem;
            width: 1rem;
            background: #E6E6E6;
          }

          .name {
            flex: 1;
            text-align: center;
            font-size: 14rem;
            color: #000000;
          }
        }

        .list-box {
          height: 36rem;
          border-bottom: 1rem solid #E6E6E6;
          display: flex;
          align-items: center;

          .activiteChoice {
            width: 12rem;
            height: 12rem;
            border: 1rem solid #00281C !important;
            margin: 0 12rem 0 10rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              display: inline-block;
              width: 8rem;
              height: 8rem;
              border-radius: 50%;
              background: #00281C;
            }
          }

          .sellOut {
            background: #F4F3F3;
          }

          .choice {
            width: 12rem;
            height: 12rem;
            border: 1rem solid #D7DBE0;
            margin: 0 12rem 0 10rem;
            border-radius: 50%;
          }

          .title {
            width: 179rem;
            text-align: center;
            font-size: 14rem;
            color: #333333;
          }

          .line {
            height: 35rem;
            width: 1rem;
            background: #E6E6E6;
          }

          .name {
            flex: 1;
            text-align: center;
            font-size: 14rem;
            color: #000000;
          }
        }
      }
    }

    .confirm {
      width: 128rem;
      height: 42rem;
      border-radius: 3rem;
      background: #00281C;
      text-align: center;
      line-height: 42rem;
      font-size: 16rem;
      color: #fff;
      margin: 46rem auto 0 auto;
    }
  }

  // 积分抵扣
  .integration {
    width: 550rem;
    height: 410rem;
    background: #fff;
    position: fixed;
    z-index: 1001;
    top: 50%;
    left: 50%;
    margin-top: -205rem;
    margin-left: -275rem;
    border-radius: 10rem;
    overflow: hidden;

    .header {
      height: 44rem;
      background: #F3F3F3;
      display: flex;
      align-items: center;
      margin-bottom: 54rem;

      p {
        font-size: 16rem;
        color: #333333;
        margin-left: 243rem;
        flex: 1;
      }

      img {
        width: 15rem;
        height: 15rem;
        margin-right: 18rem;
      }
    }

    .list {
      margin-left: 110rem;
      display: flex;
      align-items: center;

      .title {
        font-size: 14rem;
        color: #333333;
        line-height: 20rem;
      }

      .integral {
        padding: 0 8rem;
        height: 18rem;
        margin-left: 20rem;
        text-align: center;
        line-height: 20rem;
        font-family: 'Anton';
        color: #fff;
        font-size: 10rem;
        background: #00281C;
      }

      .use {
        width: 228rem;
        height: 36rem;
        border-radius: 3rem;
        border: 1rem solid #E6E6E6;
        margin-left: 12rem;
        padding-left: 12rem;
      }
    }

    .msg {
      font-size: 14rem;
      color: #999999;
      line-height: 20rem;
      margin: 10rem 0 0 102rem;
    }

    .line {
      width: 340rem;
      height: 1rem;
      background: #00281C;
      margin: 25rem auto;
    }

    .confirm {
      width: 126rem;
      height: 42rem;
      border-radius: 3rem;
      background: #00281C;
      text-align: center;
      line-height: 42rem;
      font-size: 16rem;
      color: #fff;
      margin: 52rem auto 0 auto;
    }
  }

  // 开发票
  .invoice {
    width: 550rem;
    background: #fff;
    position: fixed;
    top: 20%;
    left: 50%;
    margin-left: -275rem;
    z-index: 1001;
    border-radius: 20rem;
    overflow: hidden;
    padding-bottom: 40rem;

    .header {
      height: 44rem;
      background: #F3F3F3;
      display: flex;
      align-items: center;

      p {
        font-size: 16rem;
        color: #333333;
        margin-left: 243rem;
        flex: 1;
      }

      img {
        width: 15rem;
        height: 15rem;
        margin-right: 18rem;
      }
    }

    .list {
      margin-left: 105rem;
      display: flex;
      align-items: center;

      .title {
        font-size: 14rem;
        color: #333333;
        margin-right: 20rem;
      }

      .type {
        width: 116rem;
        height: 32rem;
        border-radius: 2rem;
        border: 1rem solid #00281C;
        text-align: center;
        line-height: 34rem;
        font-size: 14rem;
        color: #333333;
        margin-right: 10rem;
      }

      .choice {
        width: 18rem;
        height: 18rem;
        border: 1rem solid #D7DBE0;
        border-radius: 10rem;
        margin-right: 8rem;
      }

      .rise {
        margin-right: 30rem;
        font-size: 14rem;
        color: #000000;
      }

      .activite {
        border: 1rem solid #00281C;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: 10rem;
          height: 10rem;
          border-radius: 50%;
          background: #00281C;
        }
      }

      input {
        width: 238rem;
        padding-left: 12rem;
        border-radius: 2rem;
        border: 1rem solid #D9D9D9;
        font-size: 14rem;
        height: 32rem;
      }
    }

    .more {
      font-size: 12rem;
      color: #296B57;
      text-decoration: underline;
      margin: 12rem 0 0 290rem;
    }

    .line {
      width: 350rem;
      height: 1rem;
      background: #00281C;
      margin: 18rem auto;
    }

    .confirm {
      width: 128rem;
      height: 42rem;
      border-radius: 3rem;
      text-align: center;
      line-height: 42rem;
      color: #fff;
      margin: 32rem auto 0 auto;
      background: #00281C;
    }
  }

  // 选择会员
  .choiceMember {
    width: 550rem;
    height: 298rem;
    background: #fff;
    border-radius: 10rem;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -148rem;
    margin-left: -275rem;
    z-index: 1001;

    .header {
      height: 44rem;
      background: #F3F3F3;
      display: flex;
      align-items: center;
      margin-bottom: 40rem;

      p {
        font-size: 16rem;
        color: #333333;
        margin-left: 243rem;
        flex: 1;
      }

      img {
        width: 15rem;
        height: 15rem;
        margin-right: 18rem;
      }
    }

    .list {
      display: flex;
      align-items: center;
      margin: 19rem 0 0 110rem;

      .title {
        width: 84rem;
        color: #000000;
        font-size: 14rem;
        margin-right: 5rem;
      }

      .input {
        width: 238rem;
        height: 32rem;
        padding-left: 18rem;
        border-radius: 2rem;
        border: 1rem solid #D9D9D9;
      }
    }

    .footerList {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 46rem;

      .btn {
        width: 128rem;
        height: 42rem;
        text-align: center;
        line-height: 42rem;
        border-radius: 3rem;
        background: #296B57;
        font-size: 16rem;
        color: #fff;
      }
    }
  }

  // 选择导购
  .guide {
    width: 550rem;
    background: #fff;
    border-radius: 10rem;
    overflow: hidden;
    position: fixed;
    top: 20%;
    left: 50%;
    margin-left: -275rem;
    z-index: 1001;
    padding-bottom: 40rem;

    .header {
      height: 44rem;
      background: #F3F3F3;
      display: flex;
      align-items: center;
      margin-bottom: 40rem;

      p {
        font-size: 16rem;
        color: #333333;
        margin-left: 243rem;
        flex: 1;
      }

      img {
        width: 15rem;
        height: 15rem;
        margin-right: 18rem;
      }
    }

    .list {
      display: flex;
      align-items: center;
      margin-left: 88rem;

      .title {
        font-size: 14rem;
        color: #333333;
        margin-right: 10rem;
      }

      .typeActivite {
        background: #00281C;
        border: 1rem solid #00281C;
        color: #fff !important;
        transition: all 0.3s ease;
      }

      .type {
        width: 76rem;
        height: 32rem;
        border-radius: 2rem;
        border: 1rem solid #D9D9D9;
        text-align: center;
        line-height: 32rem;
        font-size: 14rem;
        color: #333333;
        margin-right: 10rem;
      }
    }

    .confirm {
      width: 128rem;
      height: 42rem;
      border-radius: 3rem;
      text-align: center;
      line-height: 42rem;
      color: #fff;
      margin: 0 auto 0 auto;
      background: #00281C;
    }
  }

  // 新增会员
  .select {
    height: 30rem !important;
    line-height: 30rem;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    width: 16rem;
    height: 16rem;
    margin-right: 6rem;
  }

  /deep/.el-radio__inner::after {
    width: 6rem;
    height: 6rem;
  }

  /deep/.el-radio__inner {
    width: 16rem;
    height: 16rem;
    margin-right: 6rem;
  }

  .addMember {
    width: 540rem;
    background: #fff;
    border-radius: 10rem;
    overflow: hidden;
    position: fixed;
    top: 20%;
    left: 50%;
    margin-left: -270rem;
    z-index: 2001;
    padding-bottom: 40rem;

    .header {
      height: 44rem;
      background: #F3F3F3;
      display: flex;
      align-items: center;
      margin-bottom: 40rem;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 11;

      p {
        font-size: 16rem;
        color: #333333;
        margin-left: 243rem;
        flex: 1;
      }

      img {
        width: 15rem;
        height: 15rem;
        margin-right: 18rem;
      }
    }

    .addMember-list {
      display: flex;
      align-items: center;
      margin-left: 50rem;
      margin-bottom: 20rem;
      position: relative;

      .name {
        font-size: 14rem;
        color: #333333;
        width: 100rem;
        text-align: right;
      }

      .input {
        width: 300rem;
        height: 36rem;
        border: 1rem solid #E6E6E6;
        border-radius: 3rem;
        outline: none;
        margin-left: 18rem;
        padding-left: 12rem;
        line-height: 36rem;
        font-size: 14rem;
      }

      /deep/.el-input__inner {
        width: 316rem;
        height: 36rem;
        border: 1rem solid #E6E6E6;
        border-radius: 3rem;
        outline: none;
        margin-left: 18rem;
        padding-left: 12rem;
      }

      .selectBox_list {
        position: absolute;
        width: 300rem;
        z-index: 99;
        background: #fff;
        left: 120rem;
        border: 1rem solid #F2F3F5;
        max-height: 400rem;
        overflow-y: auto;
        overflow-x: hidden;
        cursor: pointer;

        .selectBox_listLi {
          width: 100%;
          height: 30rem;
          line-height: 30rem;
          text-align: left;
          margin-left: 20rem;
        }

        .selectBox_listLi:hover {
          background: #f5f7fa;
        }
      }
    }

    .addMember-btn {
      width: 140rem;
      height: 50rem;
      background: #00281C;
      border-radius: 3rem;
      text-align: center;
      line-height: 50rem;
      font-size: 16rem;
      color: #fff;
      margin: 40rem auto 0 auto;
    }
  }

  // 新增收货地址
  .addAdress {
    width: 540rem;
    background: #fff;
    border-radius: 10rem;
    overflow: hidden;
    position: fixed;
    top: 20%;
    left: 50%;
    margin-left: -270rem;
    z-index: 1010;
    padding-bottom: 40rem;

    .header {
      height: 44rem;
      background: #F3F3F3;
      display: flex;
      align-items: center;
      margin-bottom: 40rem;

      p {
        font-size: 16rem;
        color: #333333;
        margin-left: 243rem;
        flex: 1;
      }

      img {
        width: 15rem;
        height: 15rem;
        margin-right: 18rem;
      }
    }

    .addMember-list {
      display: flex;
      align-items: center;
      margin-left: 50rem;
      margin-bottom: 20rem;
      position: relative;

      .name {
        font-size: 14rem;
        color: #333333;
        width: 100rem;
        text-align: right;
      }

      .input {
        width: 300rem;
        height: 36rem;
        border: 1rem solid #E6E6E6;
        border-radius: 3rem;
        outline: none;
        margin-left: 18rem;
        padding-left: 12rem;
        line-height: 36rem;
        font-size: 14rem;
      }
    }

    .btnclass {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30rem;
    }

    .preservationAddress {
      width: 128rem;
      height: 42rem;
      background: #00281C;
      text-align: center;
      line-height: 42rem;
      font-size: 16rem;
      color: #fff;
      margin: 20rem auto 0 auto;
    }
  }

  // 挂单列表
  .hanging {
    width: 1200rem;
    background: #fff;
    height: 700rem;
    border-radius: 10rem;
    overflow: hidden;
    position: fixed;
    top: 10%;
    left: 50%;
    margin-left: -600rem;
    z-index: 1010;
    padding-bottom: 40rem;

    .box {
      position: relative;
      width: 100%;
      height: 100%;
      background: #fff;

      .header {
        position: absolute;
        top: 0;
        width: 100%;
        height: 44rem;
        background: #F3F3F3;
        display: flex;
        align-items: center;
        margin-bottom: 40rem;

        p {
          font-size: 16rem;
          color: #333333;
          margin-left: 570rem;
          flex: 1;
        }

        img {
          width: 15rem;
          height: 15rem;
          margin-right: 18rem;
        }
      }

      .detail {
        padding-top: 64rem;

        .search {
          display: flex;
          align-items: center;
          margin-left: 30rem;

          .search-input {
            width: 200rem;
            height: 32rem;
            border: 1rem solid #d9d9d9;
            border-radius: 3rem;
            margin-left: 10rem;
            font-size: 14rem;
          }

          .search-btn {
            width: 78rem;
            height: 32rem;
            border-radius: 2rem;
            background: #00281C;
            text-align: center;
            line-height: 32rem;
            color: #fff;
            font-size: 14rem;
            border: 1rem solid #00281C;
            margin-left: 20rem;
          }
        }

        .list {
          width: 1140rem;
          margin: 30rem;

          .list-top {
            width: 100%;
            height: 40rem;
            background: #f0f2f6;
            display: flex;
            align-items: center;
            border: 1rem solid #dce2ee;
            color: #666;
            font-weight: 600;
            font-size: 14rem;

            .list-top-title {
              border-right: 1rem solid #e8e8e8;
              text-align: center;
              height: 40rem;
              line-height: 40rem;
            }
          }

          .list-box {
            width: 100%;
            height: 40rem;
            border: 1rem solid #dce2ee;
            font-size: 14rem;
            height: 530rem;
            overflow-y: auto;
            overflow-x: hidden;
            // border: 1rem solid #dce2ee;
            border-top: none;

            .list-order {
              // width: 100%;
              min-height: 40rem;
              display: flex;
              // align-items: center;
              border-bottom: 1rem solid #dce2ee;
              margin-top: -1rem;
              border-top: none;
              color: #333;
              font-size: 14rem;

              .list-top-title {
                border-right: 1rem solid #e8e8e8;
                text-align: center;
                // height: 100%;
                line-height: 40rem;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
</style>
